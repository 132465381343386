import FormParkBuilding from '../../components/form/Park/FormParkBuilding';
import ParkLayout from '../../components/templates/ParkLayout/ParkLayout';

const ParkBuilding = () => {
  return (
    <ParkLayout title={'Suivi bâtiment'}>
      <FormParkBuilding/>
    </ParkLayout>
  )
}

export default ParkBuilding;