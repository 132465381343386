import styles from '../Modal.module.scss';
import {
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24,
} from '../../../constants';

import { ImCross } from 'react-icons/im';
import { useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import CancelOnGoingActionModal from './CancelOnGoingActionModal';
import ValidateOnGoingActionModal from './ValidateOnGoingAction';
import Button60 from '../../button/Button60/Button60';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";

const ActionOnGoingModal = ({ data, modalVisible, setModalVisible, setRefresh }) => {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [cancelModal, setCancelModal] = useState(false);
  const [validateModal, setValidateModal] = useState(false);

  return (
    <>
      <BaseModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        {data !== null &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Détail de l'action</label>
              <div onClick={() => {
                setIsLoading(false);
                setModalVisible(!modalVisible);
              }}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.WrapperRow}>
              {CONTAINER_REGEX.test(data.num_remorque)
                ? <label className={styles.Text}>Conteneur :</label>

                : <label className={styles.Text}>Remorque :</label>
              }
              <label className={styles.ModalText}>
                <label className={styles.ModalTextBold}>{data.num_remorque}</label>
              </label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.Text}>Position actuelle :</label>
              <label className={styles.ModalText}>
                <label className={styles.ModalTextBold}>{data.batiment}-{data.emplacement}</label>
              </label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.Text}>Intitulé action :</label>
              <label
                className={styles.ModalText}>{data.libelle}</label>
            </div>
            {data.information_complementaire !== '' &&
              <div className={styles.WrapperRow}>
              <label className={styles.Text}>Informations complémentaires :</label>
              <label
                className={styles.ModalText}>{data.information_complementaire !== null ? data.information_complementaire : 'Aucunes informations'}</label>
            </div>}
            {(auth.role === 1 || auth.role === 3 || auth.role === 4) &&
              <div className={styles.ButtonWrapper}>
              <ModalCancelButton
                label={'Annuler'}
                onClick={() => {
                  setModalVisible(!modalVisible);
                  setCancelModal(true);
                }}
                isLoading={isLoading}
              />
              <Button60
                label={'Valider'}
                onClick={() => {
                  setModalVisible(!modalVisible);
                  setValidateModal(true);
                }}
                isLoading={isLoading}
              />
            </div>
            }
          </>
        }
      </BaseModal>
      <CancelOnGoingActionModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={cancelModal}
        setModalVisible={setCancelModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <ValidateOnGoingActionModal
        data={data}
        modalVisible={validateModal}
        setModalVisible={setValidateModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default ActionOnGoingModal;