import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import FormTemperatureOnCall from '../../components/form/Temperature/FormTemperatureOnCall';

const TemperatureOnCall = () => {
  return (
    <DefaultLayout title={'Astreinte température'}>
      <FormTemperatureOnCall/>
    </DefaultLayout>
  )
}

export default TemperatureOnCall;