import { useState } from 'react';
import styles from './CustomText.module.scss';
import ButtonTemperature from "../button/ButtonTemperature/ButtonTemperature";
import EditTemperature from "../modal/Temperature/EditTemperature";
import TemperatureHistoryModal from "../modal/SearchTrailer/TemperatureHistoryModal";
import useAuth from "../../hooks/useAuth";

const CustomTemperatureText = ({ data, isLoading, setIsLoading, setRefresh }) => {
  const { auth } = useAuth();

  const [clicked, setClicked] = useState(false);
  const [editTemperatureModal, setEditTemperatureModal] = useState(false);
  const [temperatureHistoryModal, setTemperatureHistoryModal] = useState(false);

  const id = data.id;
  const toggle = id => {
    if (clicked === id) {
      return setClicked(null);
    }

    setClicked(id);
  }

  const containerClass = data.en_degivrage ? styles.Wrapper : data.plus_de_6h ? styles.WrapperRed : styles.Wrapper;

  return (
    <div className={styles.WrapperColumn}>
      <div className={styles.TextWrapperColumn}>
        <div className={containerClass} onClick={async () => {
          toggle(id);
        }}>
          <div className={styles.WrapperRow}>
            <div className={styles.WrapperRow} onClick={() => setTemperatureHistoryModal(true)}>
              <label
                className={styles.TextBold}>{data.code_batiment + '-' + data.emplacement + ' : ' + data.num_remorque}</label>
              <label className={styles.Text}>Théorique: {data.temperature_theorique}°C</label>
            </div>

            {
              data.en_degivrage ?
                <ButtonTemperature
                  label={'En dégivrage'}
                  onClick={() => setEditTemperatureModal(true)}
                  isLoading={isLoading}
                  disabled
                /> :
                <ButtonTemperature
                  label={'Modifier'}
                  onClick={() => setEditTemperatureModal(true)}
                  isLoading={isLoading}
                />
            }
          </div>
        </div>
      </div>
      <TemperatureHistoryModal
        data={data}
        modalVisible={temperatureHistoryModal}
        setModalVisible={setTemperatureHistoryModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <EditTemperature
        data={data}
        modalVisible={editTemperatureModal}
        setModalVisible={setEditTemperatureModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </div>
  )
}

export default CustomTemperatureText;
