import styles from './Widget.module.scss';
import { Link } from 'react-router-dom';

const Widget = ({ title_1 = '', title_2 = '', icon, path }) => {

  return (
    <div className={styles.Widget}>
      <Link
        className={styles.OuterWidget}
        to={path}
      >
        <div className={styles.InnerWidget}>
          {icon}
        </div>
      </Link>
      <label className={'Label'}>{title_1}</label>
      <label className={'Label'}>{title_2}</label>
    </div>
  );
}

export default Widget;