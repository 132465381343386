import { api } from '../api/axios';
import { useEffect } from 'react';
import useAuth from './useAuth';
import CookieService from '../services/CookieService';
import useRefreshToken from './useRefreshToken';
import { useNavigate } from 'react-router-dom';

const useAxiosApi = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = CookieService.get('access_token');

  useEffect(() => {

    const requestIntercept = api.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
      }, (error) => {
        return Promise.reject(error);
      }
    );
    const responseIntercept = api.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const response = await refresh();
          if (response.success) {
            prevRequest.headers['Authorization'] = `Bearer ${response.token}`;
            return api(prevRequest);
          } else {
            navigate('/login');
            CookieService.remove('access_token');
            CookieService.remove('refresh_token');
            return;
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.request.eject(requestIntercept);
      api.interceptors.response.eject(responseIntercept);
    }
  }, [auth, refresh])

  return api;
};

export default useAxiosApi;
