import styles from '../Modal.module.scss';
import {
  CONTAINER_REGEX,
  ICON_SIZE14,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL,
  TRAILER_URL
} from '../../../constants';

import { ImCross } from 'react-icons/im';
import { FaArrowRight } from 'react-icons/fa';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import ModalModifyButton from '../../button/ModalButton/ModalModifyButton';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import { useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModifyModal from './ModifyModal';
import ValidateModal from './ValidateModal';
import { FcViewDetails } from 'react-icons/fc';
import CancelOnGoingMissionModal from './CancelOnGoingMissionModal';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const moment = require('moment-timezone');


const OnGoingModal = ({ data, isLoading, setIsLoading, modalVisible, setModalVisible, setRefresh }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [validateModalVisible, setValidateModalVisible] = useState(false);

  const validationMoveRequest = async () => {
    if (data.emplacement_demande === 0) {
      setModalVisible(!modalVisible);
      setValidateModalVisible(!validateModalVisible);
    } else {
      validateMoveRequest();
    }
  }
  const validateMoveRequest = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Validation en cours...');
    await axiosApi.post(`${TRAILER_URL}/check/position`,
      {
        code_batiment: data.code_batiment_demande,
        emplacement: data.emplacement_demande,
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error('Cet emplacement est déjà occupé. Veuillez changer d\'emplacement.', { id: toastLoading });
          setIsLoading(false);
        } else {
          await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
            {
              id: data.id,
              id_statut: 20,
              id_utilisateur: auth.id
            })
            .then((result) => {
              setIsLoading(false);
              if (result.data.success) {
                toast.success('Mission validée', { id: toastLoading });
                setRefresh(true);
              } else {
                toast.error('Une erreur est survenue lors de la validation de la mission', { id: toastLoading });
              }
            })
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <>
      <BaseModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        isLoading={isLoading}
      >
        {data !== null &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Détail de la mission</label>
              <div onClick={() => setModalVisible(!modalVisible)}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>
                <label
                  className={styles.ModalText}> {CONTAINER_REGEX.test(data.num_remorque) ? 'Conteneur :' : 'Remorque :'}</label>
              </label>
              <div>
                <label className={styles.ModalTextBold}>{data.num_remorque}</label>
                : {data.code_batiment_actuel}-{data.emplacement_actuel}
                {<FaArrowRight size={ICON_SIZE14} className={styles.ArrowIcon}/>}
                {data.code_batiment_demande}-{data.emplacement_demande} {moment(data.date_demande.substring(0, 16)).format('HH[h]')}
              </div>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Tractionnaire :</label>
              <label className={styles.ModalText}>{data.libelle}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Demandeur :</label>
              <label
                className={styles.ModalText}> {data.prenom} le {moment(data.date_creation.substring(0, 16)).format('DD/MM/YYYY [à] HH[h]mm')}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Informations compl. :</label>
              <label
                className={styles.ModalText}>{data.information_complementaire !== '' ? data.information_complementaire : 'Aucunes informations'}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>N°de quai :</label>
              <label
                className={styles.ModalText}>{data.emplacement_demande !== '' ? data.emplacement_demande : 'Inconnu'}</label>
            </div>
            {(auth.role === 1 || auth.role === 3 || auth.role === 4) &&
              <div className={styles.ButtonWrapper}>
                <ModalCancelButton
                  label={'Annuler'}
                  onClick={() => {
                    setModalVisible(!modalVisible);
                    setCancelModalVisible(!cancelModalVisible);
                  }}
                  isLoading={isLoading}
                />
                <ModalModifyButton
                  label={'Modifier'}
                  onClick={() => {
                    setModalVisible(!modalVisible)
                    setModifyModalVisible(!modifyModalVisible);
                  }}
                  isLoading={isLoading}
                />
                <ModalSuccessButton label={'Valider'} onClick={() => {
                  validationMoveRequest();
                }}
                                    isLoading={isLoading}/>
              </div>
            }
          </>
        }
      </BaseModal>
      <CancelOnGoingMissionModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={cancelModalVisible}
        setModalVisible={setCancelModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <ModifyModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={modifyModalVisible}
        setModalVisible={setModifyModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <ValidateModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={validateModalVisible}
        setModalVisible={setValidateModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default OnGoingModal;