import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss'
import CookieService from '../../services/CookieService';
import logo from '../../assets/icon.png';
import { GiExitDoor } from "react-icons/gi";

const Header = ({ title }) => {
  const { setAuth } = useAuth()
  const navigate = useNavigate()

  const logout = async () => {
    sessionStorage.clear();
    CookieService.remove('access_token');
    CookieService.remove('refresh_token');
    setAuth({});
    navigate('/');
  }

  return (
    <header className={styles.Header}>
      <div onClick={() => {
        navigate('/')
      }} className={styles.ContainerLogo}>
        <img className={styles.Logo} src={logo} alt='Logo'/>
      </div>
      <p className={styles.Center}>{title}</p>
      <div className={styles.IconWrapper}><GiExitDoor size={34} className={styles.Icon} onClick={logout}/></div>
    </header>
  )
}

export default Header;
