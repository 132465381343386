import { Body } from '@react-email/body';
import { Container } from '@react-email/container';
import { Head } from '@react-email/head';
import { Heading } from '@react-email/heading';
import { Hr } from '@react-email/hr';
import { Html } from '@react-email/html';
import { Text } from '@react-email/text';
import * as React from 'react';

const NewMissionBuildingEmail = ({
                                   container,
                                   information_complementaire,
                                   code_batiment,
                                   emplacement,
                                   demandeur,
                                   date
                                 }) => {

  return (
    <Html>
      <Head/>
      <Body style={main}>
        <Container style={ct}>
          <Heading style={h1}>
            Mission créée à destination du bâtiment {code_batiment}
          </Heading>
          <table style={table}>
            <tr>
              <th style={t}>Container</th>
              <th style={t}>Informations comp.</th>
              <th style={t}>Quai</th>
              <th style={t}>Demandeur</th>
              <th style={t}>Date</th>
            </tr>
            <tr>
              <td style={t}>{container.toUpperCase()}</td>
              <td style={t}>{information_complementaire}</td>
              <td style={t}>{emplacement}</td>
              <td style={t}>{demandeur}</td>
              <td style={t}>{date}</td>
            </tr>
          </table>
          <Hr style={hr}/>
          <Text style={footer}>
            Ne pas répondre à ce mail.
          </Text>
        </Container>
      </Body>
    </Html>
  );
}

const main = {
  backgroundColor: '#ffffff',
  margin: '0 auto',
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  width: '100vh',
};

const table = {
  border: '1px solid #000000',
  borderCollapse: 'collapse',
  textAlign: 'center',
};

const t = {
  border: '1px solid #000000',
  padding: '12px',
};


const ct = {
  border: '1px solid #000000',
  borderRadius: '5px',
  margin: '40px auto',
  padding: '10px',
  width: '100vh',
  textAlign: 'center',
};

const h1 = {
  color: '#000',
  fontSize: '24px',
  fontWeight: 'normal',
  textAlign: 'center',
  margin: '30px 0',
  padding: '0',
};

const hr = {
  border: 'none',
  borderTop: '1px solid #eaeaea',
  margin: '26px 0',
  width: '100%',
};

const footer = {
  color: '#666666',
  fontSize: '12px',
  lineHeight: '24px',
  textAlign: 'left',
};

const logo = {
  margin: '0 auto',
};

export default NewMissionBuildingEmail;