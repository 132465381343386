import { useContext, useEffect, useState } from 'react';
import CustomLabel from '../label/CustomLabel';
import CustomSearchableSelect from '../select/CustomSearchableSelect/CustomSearchableSelect';
import styles from './Forms.module.scss';
import BasicCheckbox from '../checkbox/BasicCheckbox';
import TextAreaInput from '../input/TextAreaInput';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import useAxiosApi from '../../hooks/useAxiosApi';
import {
  API_URL,
  BUILDING_URL,
  CONTAINER_REGEX,
  DATE_REGEX, EMPTY_REGEX, FULL_REGEX,
  MOVINGREQUEST_URL,
  SLOT_URL,
  STATUS_URL, TIME_REGEX,
  TRAILER_URL
} from '../../constants';
import useAuth from '../../hooks/useAuth';
import { formatDate, getSelected } from '../../utils/functions';
import CartModal from "../modal/Cart/CartModal";
import { CartContext } from "../../context/CartContext";
import Button30 from "../button/Button30/Button30";
import toast from "react-hot-toast";
import { render } from "@react-email/render";
import TemperatureAlertsEmail from "../mail/TemperatureAlertsEmail";
import NewMissionBuildingEmail from "../mail/NewMissionBuildingEmail";

registerLocale('fr', fr);

const FormMoveRequest = () => {
  const { auth } = useAuth();
  const cart = useContext(CartContext);

  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [trailers, setTrailers] = useState('');
  const [selectedTrailer, setSelectedTrailer] = useState('');
  const [buildings, setBuildings] = useState('');
  const [slots, setSlots] = useState([]);
  const [status, setStatus] = useState('')
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isPlugged, setIsPlugged] = useState(false);
  const [additionalInformation, setAdditionalInformation] = useState('')
  const [date, setDate] = useState(new Date());
  const [showCart, setShowCart] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    await axiosApi.get(`${TRAILER_URL}/all`)
      .then(async (result) => {
        setTrailers(result.data.list);
        await axiosApi.get(`${BUILDING_URL}/all`)
          .then(async (result) => {
            setBuildings(result.data.list);
            await axiosApi.get(`${STATUS_URL}/trailer`)
              .then((result) => {
                setStatus(result.data.list);
                setSelectedStatus(getSelected(result.data.list, 1)[0]);
                toast.remove(toastLoading);
                setIsLoading(false);
              })
              .catch((err) => {
                toast.remove(toastLoading);
                setIsLoading(false);
              })
          })
          .catch((err) => {
            toast.remove(toastLoading);
            setIsLoading(false);
          })
      })
      .catch((err) => {
        toast.remove(toastLoading);
        setIsLoading(false);
      })
  }

  const createNewMoveRequest = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Création en cours...');

    let fDate = '';
    let fTime = '';

    if (date !== null) {
      fDate = ('0' + date.getUTCDate()).slice(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '/' + date.getFullYear();
      fTime = ('0' + date.getHours()).slice(-2) + 'h' + ('0' + date.getMinutes()).slice(-2);
    }

    if (selectedTrailer === '') {
      toast.error('Veuillez sélectionner un conteneur/remorque', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    await axiosApi.post(`${MOVINGREQUEST_URL}/check`,
      {
        id: selectedTrailer.id
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error(CONTAINER_REGEX.test(selectedTrailer.label) ? 'Vous avez déjà une demande de déplacement pour ce conteneur.' : 'Vous avez déjà une demande de déplacement pour cette remorque.', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        if (selectedBuilding === '') {
          toast.error('Veuillez sélectionner un bâtiment', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (selectedSlot === '') {
          toast.error('Veuillez sélectionner un emplacement/quai', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        if (fDate === '' || fTime === '') {
          toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (!DATE_REGEX.test(fDate)) {
          toast.error('Veuillez entrer une date valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (!TIME_REGEX.test(fTime)) {
          toast.error('Veuillez entrer une heure valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (selectedStatus === '') {
          toast.error('Veuillez sélectionner un statut', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        let emailHtml = '';
        emailHtml = render(<NewMissionBuildingEmail container={selectedTrailer.label} code_batiment={selectedBuilding.id}
                                                    emplacement={selectedSlot.id}
                                                    information_complementaire={additionalInformation}
                                                    demandeur={auth.firstname} date={fDate + ' ' + fTime}/>);
        const newMoveRequest = {
          date_demande: formatDate(fDate, fTime),
          information_complementaire: additionalInformation,
          code_batiment: selectedBuilding.id,
          emplacement: selectedSlot.id,
          id_remorque: selectedTrailer.id,
          id_deplacement_statut: selectedStatus.id,
          id_utilisateur: parseInt(auth.id),
          est_branche: isPlugged,
          html: emailHtml
        }

        axiosApi.post(`${MOVINGREQUEST_URL}/create`,
          {
            newMoveRequest
          })
          .then((result) => {
            if (result.data.success) {
              toast.success('La mission a été créée !', { id: toastLoading });
              setIsLoading(false);
              sendNotification()
                .then((result) => {
                  if (result.status === 201) {
                    toast('Une notification a été envoyée aux chauffeurs de parc.',
                      {
                        icon: '📧',
                      })
                    console.log('Notification envoyé !')
                  } else if (result.status === 409) {
                    console.log('Aucun utilisateur à notifier')
                  } else {
                    console.log('Une erreur est survenue lors de l\'envoi de la notification');
                  }
                });
            } else {
              toast.error('Erreur lors de la création !', { id: toastLoading });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error('Une erreur est survenue', { id: toastLoading });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err)
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }
  const addToCart = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Veuillez patienter...');
    let fDate = '';
    let fTime = '';

    if (date !== null) {
      fDate = ('0' + date.getUTCDate()).slice(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '/' + date.getFullYear();
      fTime = ('0' + date.getHours()).slice(-2) + 'h' + ('0' + date.getMinutes()).slice(-2);
    }

    if (selectedTrailer === '') {
      toast.error('Veuillez sélectionner un conteneur/remorque', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    await axiosApi.post(`${MOVINGREQUEST_URL}/check`,
      {
        id: selectedTrailer.id
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error(CONTAINER_REGEX.test(selectedTrailer.label) ? 'Vous avez déjà une demande de déplacement pour ce conteneur.' : 'Vous avez déjà une demande de déplacement pour cette remorque.', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        if (selectedBuilding === '') {
          toast.error('Veuillez sélectionner un bâtiment', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (selectedSlot === '') {
          toast.error('Veuillez sélectionner un emplacement/quai', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        if (fDate === '' || fTime === '') {
          toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (!DATE_REGEX.test(fDate)) {
          toast.error('Veuillez entrer une date valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (!TIME_REGEX.test(fTime)) {
          toast.error('Veuillez entrer une heure valable', { id: toastLoading });
          setIsLoading(false);
          return;
        }
        if (selectedStatus === '') {
          toast.error('Veuillez sélectionner un statut', { id: toastLoading });
          setIsLoading(false);
          return;
        }

        let emailHtml = '';
        emailHtml = render(<NewMissionBuildingEmail container={selectedTrailer.label} code_batiment={selectedBuilding.id}
                                                    emplacement={selectedSlot.id}
                                                    information_complementaire={additionalInformation}
                                                    demandeur={auth.firstname} date={fDate + ' ' + fTime}/>);
        const newMoveRequest = {
          id_remorque: selectedTrailer.id,
          num_remorque: selectedTrailer.label,
          code_batiment: selectedBuilding.id,
          emplacement: selectedSlot.id,
          date_demande: formatDate(fDate, fTime),
          id_deplacement_statut: selectedStatus.id,
          status_label: selectedStatus.label,
          est_branche: isPlugged,
          information_complementaire: additionalInformation,
          id_utilisateur: parseInt(auth.id),
          html: emailHtml,
          timestamp: new Date().toISOString()
        }

        cart.addToCart(newMoveRequest.id_remorque, JSON.stringify(newMoveRequest));
        toast.success('La mission a été ajoutée au panier !', { id: toastLoading });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const sendNotification = async () => {
    return await axiosApi.get(`${API_URL}/notifications/new-mission`);
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(false);
      }
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      const toastLoading = toast.loading('Chargement des données...');
      axiosApi.post(`${SLOT_URL}/code`,
        {
          code: selectedBuilding.value
        })
        .then((result) => {
          setSlots(result.data.list);
          setSelectedSlot(getSelected(result.data.list, 0)[0]);
          toast.remove(toastLoading);
        })
        .catch((err) => {
          console.log('ERROR ', err);
          toast.error('Une erreur est survenue', { id: toastLoading });
        });
    }
  }, [selectedBuilding])

  return (
    <>
      <div className={styles.Form}>
        <div className={styles.Wrapper}>
          <CustomLabel
            label={'N°Conteneur / Remorque'}
          />
          <CustomSearchableSelect
            data={trailers}
            placeholder={'ex: CAXU123456/7 ou AA-001-AA'}
            setValue={setSelectedTrailer}
            defaultValue={selectedTrailer}
            searchable
          />
        </div>

        <div className={styles.SplitSelect}>
          <div className={styles.position}>
            <div className={styles.Wrapper}>
              <CustomLabel
                label={'Bâtiment'}
              />
              <CustomSearchableSelect
                data={buildings}
                placeholder={'Sélectionner un bâtiment'}
                setValue={setSelectedBuilding}
                defaultValue={selectedBuilding}
              />
            </div>
          </div>
          <div className={styles.position}>
            <div className={styles.Wrapper}>
              <CustomLabel
                label={'Emplacement/Quai'}
              />
              <CustomSearchableSelect
                data={slots}
                placeholder={'Sélectionner un emplacement/quai'}
                setValue={setSelectedSlot}
                defaultValue={selectedSlot}
              />
            </div>
          </div>
        </div>
        <div className={styles.SplitSelect}>
          <div className={styles.position}>
            <CustomLabel
              label={'Date et heure'}
            />
            <DatePicker
              selected={date}
              onChange={(d) => setDate(d)}
              timeInputLabel='Heure:'
              dateFormat='dd/MM/yyyy HH:mm'
              showTimeInput
              locale='fr'
              customInput={<input className={styles.DatePicker} type='text' value={date}/>}
            />
          </div>
        </div>
        <div className={styles.Wrapper}>
          <CustomLabel
            label={'Choisir un statut'}
          />
          <CustomSearchableSelect
            data={status}
            placeholder={'Sélectionner un statut'}
            setValue={setSelectedStatus}
            defaultValue={selectedStatus}
          />
        </div>
        <div className={styles.Wrapper}>
          <BasicCheckbox label={'branché'} value={isPlugged} setValue={setIsPlugged} disabled={FULL_REGEX.test(selectedStatus.libelle) || EMPTY_REGEX.test(selectedStatus.libelle)}/>
        </div>
        <div className={styles.Wrapper}>
          <CustomLabel
            label={'Informations complémentaires'}
          />
          <TextAreaInput
            value={additionalInformation}
            setValue={setAdditionalInformation}
            placeholder={'Informations complémentaires'}
            maxLength={500}
          />
        </div>
        <div className={styles.ButtonWrapper}>
          <Button30
            label={'Créer la mission'}
            onClick={() => {
              createNewMoveRequest();
            }}
            isLoading={isLoading}
          />
          {(auth.role === 3 || auth.role === 4) &&
            <>
              <Button30
                label={'Ajouter au panier'}
                onClick={() => {
                  addToCart();
                }}
                isLoading={isLoading}
              />
              <Button30
                label={'Voir panier'}
                onClick={() => {
                  setShowCart(true);
                }}
                isLoading={isLoading}
              />
            </>
          }
        </div>
      </div>
      <CartModal
        modalVisible={showCart}
        setModalVisible={setShowCart}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default FormMoveRequest;