import DefaultLayout from '../components/templates/DefaultLayout/DefaultLayout';
import FormExport from '../components/form/FormExport';

const Export = () => {
  return (
    <DefaultLayout title={'Export CSV'}>
      <FormExport/>
    </DefaultLayout>
  )
}

export default Export;