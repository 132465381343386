import useAxiosApi from '../../hooks/useAxiosApi';
import { useState } from 'react';
import styles from './CustomText.module.scss';
import { ACTION_URL } from '../../constants';
import ActionOnGoingModal from '../modal/ParkState/ActionOnGoingModal';
import toast from "react-hot-toast";

const CustomOnGoingActionText = ({ data, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();

  const [dataDetails, setDataDetails] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);

  const getActionDetails = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');

    await axiosApi.post(`${ACTION_URL}/details`,
      {
        id: data.id
      })
      .then((result) => {
        setDataDetails(result.data.list);
        setModalVisible(true);
        setIsLoading(false);
        toast.remove(toastLoading);
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className={styles.Wrapper} onClick={async () => {
        getActionDetails();
      }}>
        <label className={styles.TextBold}>{data.prenom} - {data.num_remorque}</label>
        <label className={styles.Text}>{data.libelle}</label>
      </div>
      <ActionOnGoingModal
        data={dataDetails}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default CustomOnGoingActionText;