import {
  ICON_SIZE16,
  ICON_SIZE24,
  TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import { useCallback, useEffect, useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import moment from "moment-timezone";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const MovementHistoryModal = ({
                                data,
                                modalVisible, setModalVisible,
                                isLoading, setIsLoading
                              }) => {
  const axiosApi = useAxiosApi();

  const [historyMovements, setHistoryMovements] = useState('');

  const fetchMovementHistory = useCallback(async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    try {

      const result = await axiosApi.post(`${TRAILER_URL}/history/movement`, { id: data.id });
      setHistoryMovements(result.data.list);
      toast.remove(toastLoading);
      setIsLoading(false);
    } catch (err) {
      toast.error('Une erreur est survenue', { id: toastLoading });
      setIsLoading(false);
    }
  }, [data, axiosApi]);

  useEffect(() => {
    if (modalVisible) {
      fetchMovementHistory();
    }
  }, [modalVisible])

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label
              className={styles.HeaderText}>{'Historique mouvements'}</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.WrapperMovementColumn}>
            {(historyMovements !== '' && historyMovements.length !== 0) ?
              historyMovements.map((movement) => {
                return <label
                  className={styles.Modal}
                  key={movement.id}
                >
                  {moment(movement.date.substring(0, 16)).format('DD/MM/YYYY HH:mm') + ' : ' + movement.code_batiment + '-' + movement.emplacement}
                </label>
              })
              : <label className={styles.Modal}>Aucun mouvement</label>
            }
          </div>
        </>
      }
    </BaseModal>
  )
}

export default MovementHistoryModal;