import Select from 'react-select';

const customTheme = (theme) => {
  return {
    ...theme,
    border: 1,
    borderColor: '#28A745',
    colors: {
      ...theme.colors,
      primary25: '#28A745',
      primary: '#28A745'
    },

  }
}

const CustomSearchableSelect = ({ data, placeholder, setValue, defaultValue = null, isClearable = false , disabled = false, searchable = false}) => {
  return (
      <Select
        theme={customTheme}
        options={data}
        onChange={setValue}
        placeholder={placeholder}
        noOptionsMessage={() => 'Aucune données'}
        value={defaultValue}
        menuPortalTarget={document.body}
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={searchable}
      />
  )
}

export default CustomSearchableSelect;