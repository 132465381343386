import { useNavigate } from 'react-router-dom';
import BasicModalButton from '../components/button/ModalButton/BasicModalButton';

const Missing = () => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        height: '100vh',
        verticalAlign: 'middle',
        minHeight: '10em'
      }}>
        <p style={{ fontSize: 40, marginBottom: 10 }}>Oops</p>
        <p style={{ marginBottom: 10 }}>Erreur 404 : Page introuvable
        </p>
        <BasicModalButton label={'Retour dans le droit chemin'} onClick={() => navigate('/')}/>
      </div>
    </>
  )
}

export default Missing;