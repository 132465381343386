import { ICON_SIZE40 } from '../../constants';
import Widget from '../../components/widget/Widget';
import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import styles from "../../styles/global.module.scss";
import { CiTempHigh } from "react-icons/ci";
import { TbClock2 } from "react-icons/tb";

const TemperatureHome = () => {
  return (
    <DefaultLayout title={'Gestion des températures'}>
      <div className={styles.WidgetWrapper}>
        <Widget
          path={'global'}
          icon={<CiTempHigh size={ICON_SIZE40}/>}
          title_1={'Suivi global'}
          title_2={''}
        />
        <Widget
          path={'oncall'}
          icon={<TbClock2 size={ICON_SIZE40}/>}
          title_1={'Astreinte'}
          title_2={'température'}
        />
      </div>
    </DefaultLayout>
  );
};

export default TemperatureHome;