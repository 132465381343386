import { ACTION_URL, CONTAINER_REGEX, ICON_SIZE16, ICON_SIZE24 } from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import TextAreaInput from '../../input/TextAreaInput';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import { useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const CancelWaitingActionModal = ({
                                    data,
                                    mainModal,
                                    setMainModal,
                                    modalVisible,
                                    setModalVisible,
                                    isLoading,
                                    setIsLoading,
                                    setRefresh
                                  }) => {
  const axiosApi = useAxiosApi();

  const [cancelReason, setCancelReason] = useState('');

  const cancelAction = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Annulation de l\'action en cours...');
    await axiosApi.post(`${ACTION_URL}/update`,
      {
        id: data.id,
        id_statut: 21,
        motif_annulation: cancelReason
      })
      .then((result) => {
        setIsLoading(false)
        if (result.data.success) {
          toast.success('Annulation de l\'action effectuée', { id: toastLoading });
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de l\'annulation', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <>
      <BaseModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        {data &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Annulation mission</label>
              <div onClick={() => {
                setModalVisible(!modalVisible)
                setMainModal(!mainModal);
              }}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.Wrapper}>
              {CONTAINER_REGEX.test(data.num_remorque)
                ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>

                : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
              }
            </div>
            <div className={styles.WrapperColumn}>
              <label className={styles.ModalText}>Motif annulation :</label>
              <TextAreaInput
                value={cancelReason}
                setValue={setCancelReason}
                placeholder={'Motif d\'annulation'}
                maxLength={500}
              />
            </div>
            <div className={styles.Wrapper}>
              <BasicModalButton
                label={'Valider annulation'}
                onClick={() => {
                  cancelAction();
                }}
                isLoading={isLoading}
              />
            </div>
          </>
        }
      </BaseModal>
    </>
  )
}

export default CancelWaitingActionModal;