import FormParkTractionnist from '../../components/form/Park/FormParkTractionnist';
import ParkLayout from '../../components/templates/ParkLayout/ParkLayout';

const ParkTractionnist = () => {
  return (
    <ParkLayout title={'Suivi tractionnaire'}>
      <FormParkTractionnist/>
    </ParkLayout>
  )
}

export default ParkTractionnist;