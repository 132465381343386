import {
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import { FcInspection } from 'react-icons/fc';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const CancelOnGoingActionModal = ({
                                    data,
                                    mainModal, setMainModal,
                                    modalVisible, setModalVisible,
                                    isLoading, setIsLoading,
                                    setRefresh
                                  }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const cancelMission = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Annulation de la mission en cours...');
    await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
      {
        id: data.id,
        id_statut: 18,
        id_utilisateur: auth.id
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('Annulation de la mission effectuée', { id: toastLoading });
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de l\'annulation', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Annulation prise en compte</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
              setMainModal(!mainModal);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          <div className={styles.Wrapper}>
            <label className={styles.ModalTextBold}> {CONTAINER_REGEX.test(data.num_remorque) ? `Conteneur : ${data.num_remorque}` : `Remorque : ${data.num_remorque}`}</label>
          </div>
          <div className={styles.Wrapper}>
            <label className={styles.ModalText}>Êtes-vous sûr de d'annuler la prise en compte de la mission ?</label>
          </div>

          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <ModalSuccessButton
              label={'Valider annulation'}
              onClick={() => {
                cancelMission();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default CancelOnGoingActionModal;