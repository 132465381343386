export const getBuildingLabel = (code) => {
  switch (code) {
    case 'A':
      return 'Bâtiment A';
    case 'A3':
      return 'Bâtiment A3';
    case 'B':
      return 'Bâtiment B';
    case 'C':
      return 'Bâtiment C';
    case 'CF':
      return 'Bâtiment CIF';
    case 'E1':
      return 'Bâtiment E1';
    case 'E2':
      return 'Bâtiment E2';
    case 'F':
      return 'Bâtiment F';
    case 'G':
      return 'Bâtiment G';
    case 'H':
      return 'Bâtiment MAEX';
    case 'J':
      return 'Mécanique';
    case 'R':
      return 'Parc extérieur';
    case 'RO':
      return 'Route';
    default:
      return 'Inconnu';
  }
}

export const getSelected = (array, compared) => {
  return array.filter(elem => elem.id === compared)
}

export const formatDate = (date, time) => {
  const splitDate = date.split('/');
  let fDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
  let fTime = time.replace('h', ':');
  return fDate + ' ' + fTime;
}

export const getFormatedDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getPathLabel = (pathName) => {
  switch (pathName) {
    case 'mission':
      return 'Missions';
    case 'need':
      return 'Missions par besoins';
    case 'arrival':
      return 'Enregistrer une arrivée';
    case 'search':
      return 'Recherche conteneur';
    case 'moverequest':
      return 'Planifier une mission';
    case 'park':
      return 'État du parc';
    case 'global':
      return 'Suivi global';
    case 'tractionnist':
      return 'Suivi tractionnaire';
    case 'building':
      return 'Suivi bâtiment';
    case 'temperature':
      return 'Gestion des températures';
    case 'oncall':
      return 'Astreinte température';
    case 'export':
      return 'Export CSV';
    default:
      return pathName;
  }
}

export const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const  isDatePast = (dateISOString) => {
  const date = new Date(dateISOString);
  date.setHours(date.getHours() + 4);
  const currentDate = new Date();
  return currentDate >= date;
}

