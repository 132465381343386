import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import FormTemperature from '../../components/form/Temperature/FormTemperature';

const Temperature = () => {
  return (
    <DefaultLayout title={'Relevé de température'}>
      <FormTemperature/>
    </DefaultLayout>
  )
}

export default Temperature;