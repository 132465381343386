import styles from './LoginLayout.module.scss';
import logo from '../../../assets/condigel.jpg'

const LoginLayout = ({ children }) => {
  return (
    <div className={styles.LoginLayout}>
      <a>
        <img src={logo} alt='Logo' height={200} width={350}/>
      </a>
      {children}
    </div>
  );
}

export default LoginLayout;