import styles from './Input.module.scss';

const CustomInput = ({ value, setValue, type = 'text', placeholder = '', maxLength = 99, disabled = false }) => {
  return (
    <input
      type={type}
      autoComplete='off'
      onChange={(e) => setValue(e.target.value)}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      className={styles.Input}
      disabled={disabled}
    />
  )
}

export default CustomInput;