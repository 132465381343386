import {
  BUILDING_URL,
  CONTAINER_REGEX, EMPTY_REGEX, FULL_REGEX,
  ICON_SIZE16,
  ICON_SIZE24,
  MOVINGREQUEST_URL,
  SLOT_URL, STATUS_URL, TRACTIONIST_URL,
  TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import BasicCheckbox from '../../checkbox/BasicCheckbox';
import { useEffect, useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import { getSelected } from "../../../utils/functions";

const ValidateModal = ({
                         data,
                         isLoading, setIsLoading,
                         mainModal, setMainModal,
                         modalVisible, setModalVisible,
                         setRefresh
                       }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [buildings, setBuildings] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState([]);
  const [slots, setSlots] = useState('');
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [status, setStatus] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isPlugged, setIsPlugged] = useState(false);

  const getSlots = async () => {
    console.log(data);
    const toastLoading = toast.loading('Chargement des données...');
    if (data.libelle === 'EFBS') {
      try {
        const buildingResult = await axiosApi.get(`${BUILDING_URL}/all`);
        setBuildings(buildingResult.data.list);
        setSelectedBuilding(getSelected(buildingResult.data.list, 'R')[0]);
        const statusResult = await axiosApi.get(`${STATUS_URL}/trailer`);
        setStatus(statusResult.data.list);
        setSelectedStatus(getSelected(statusResult.data.list, data.id_statut_deplacement)[0]);
        toast.remove(toastLoading);

      } catch (err) {
        toast.error('Une erreur est survenue lors du chargement des données', { id: toastLoading });
      } finally {
        setIsLoading(false);
      }
    } else {
      const code = data.code_batiment_demande;
      await axiosApi.post(`${SLOT_URL}/code`,
        {
          code
        })
        .then((result) => {
          setSlots(result.data.list);
          toast.remove(toastLoading);
        })
        .catch((err) => {
          toast.remove();
          toast.error('Une erreur est survenue');
        });
    }
  }

  const initValidateModal = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement des données...');
    await getSlots()
      .then(() => {
          let checked = false;
          if (data.est_branche) {
            checked = true;
          }
          setIsPlugged(checked)
          setIsLoading(false);
          toast.remove(toastLoading);
        }
      )
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }
  const validateMoveRequest = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Validation de la mission en cours...');
    data.emplacement_demande = selectedSlot.id;
    data.est_branche = isPlugged;
    if (data.emplacement_demande === 0) {
      if (data.libelle === 'EFBS') {
        await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
          {
            id: data.id,
            id_statut: 20,
            code_batiment: selectedBuilding.id,
            emplacement: selectedSlot.id,
            est_branche: isPlugged,
            id_statut_deplacement: selectedStatus.id,
            id_utilisateur: auth.id
          })
          .then((result) => {
            if (result.data.success) {
              toast.success('Validation de la mission effectuée.', { id: toastLoading })
              setIsLoading(false);
              setRefresh(true)
            } else {
              toast.error('Une erreur est survenue lors de la validation de la mission', { id: toastLoading });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            toast.error('Une erreur est survenue', { id: toastLoading });
            setIsLoading(false);
          })
      } else {
        await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
          {
            id: data.id,
            id_statut: 20,
            emplacement: data.emplacement_demande,
            est_branche: data.est_branche,
            id_utilisateur: auth.id
          })
          .then((result) => {
            if (result.data.success) {
              toast.success('Validation de la mission effectuée.', { id: toastLoading });
              setIsLoading(false);
              setRefresh(true);
            } else {
              toast.error('Une erreur est survenue lors de la validation de la mission', { id: toastLoading });
              setIsLoading(false);
            }
          })
          .catch((err) => {
            toast.error('Une erreur est survenue', { id: toastLoading });
            setIsLoading(false);
          });
      }
    } else {
      await axiosApi.post(`${TRAILER_URL}/check/position`,
        {
          code_batiment: data.code_batiment_demande,
          emplacement: data.emplacement_demande,
        })
        .then(async (result) => {
          if (result.data.success) {
            toast.error('Cet emplacement est déjà occupé. Veuillez changer d\'emplacement.', { id: toastLoading });
            setIsLoading(false);
          } else {
            if (data.libelle === 'EFBS') {
              await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
                {
                  id: data.id,
                  id_statut: 20,
                  code_batiment: selectedBuilding.id,
                  emplacement: selectedSlot.id,
                  est_branche: isPlugged,
                  id_statut_deplacement: selectedStatus.id,
                  id_utilisateur: auth.id
                })
                .then((result) => {
                  if (result.data.success) {
                    toast.success('Validation de la mission effectuée.', { id: toastLoading })
                    setIsLoading(false);
                    setRefresh(true)
                  } else {
                    toast.error('Une erreur est survenue lors de la validation de la mission', { id: toastLoading });
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  toast.error('Une erreur est survenue', { id: toastLoading });
                  setIsLoading(false);
                })
            } else {
              await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
                {
                  id: data.id,
                  id_statut: 20,
                  emplacement: data.emplacement_demande,
                  est_branche: data.est_branche,
                  id_utilisateur: auth.id
                })
                .then((result) => {
                  if (result.data.success) {
                    toast.success('Validation de la mission effectuée.', { id: toastLoading })
                    setIsLoading(false);
                    setRefresh(true)
                  } else {
                    toast.error('Une erreur est survenue lors de la validation de la mission', { id: toastLoading });
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  toast.error('Une erreur est survenue', { id: toastLoading });
                  setIsLoading(false);
                })
            }
          }
        })
    }
  }

  useEffect(() => {
    if (modalVisible) {
      setSelectedSlot([]);
      initValidateModal();
    }
  }, [modalVisible])

  useEffect(() => {
    if (slots !== '' && slots.length !== 0) {
      setSelectedSlot(slots[0])
    }
  }, [slots]);

  useEffect(() => {
    if (selectedBuilding !== '') {
      const toastLoading = toast.loading('Chargement en cours...');
      axiosApi.post(`${SLOT_URL}/code`,
        {
          code: selectedBuilding.value
        })
        .then((result) => {
          setSlots(result.data.list);
          setSelectedSlot(getSelected(result.data.list, 0)[0]);
          toast.remove(toastLoading);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('ERROR ', err);
          toast.error('Une erreur est survenue', { id: toastLoading });
        });
    }
  }, [selectedBuilding])

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(false);
      }
    }
  }, [selectedStatus]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Validation mission</label>
            <div onClick={() => {
              setSelectedSlot([]);
              setModalVisible(!modalVisible);
              setMainModal(!mainModal);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>

              : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            }
          </div>
          {
            data.libelle === 'EFBS' ?
              <div className={styles.Wrapper}>
                <div className={styles.SplitSelect}>
                  <div className={styles.position}>
                    <div className={styles.Wrapper}>
                      <CustomLabel
                        label={'Bâtiment'}
                      />
                      <CustomSearchableSelect
                        data={buildings}
                        placeholder={'Sélectionner un bâtiment'}
                        setValue={setSelectedBuilding}
                        defaultValue={selectedBuilding}
                      />
                    </div>
                  </div>
                  <div className={styles.position}>
                    <div className={styles.Wrapper}>
                      <CustomLabel
                        label={'Emplacement/Quai'}
                      />
                      <CustomSearchableSelect
                        data={slots}
                        placeholder={'Sélectionner un emplacement/quai'}
                        setValue={setSelectedSlot}
                        defaultValue={selectedSlot}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.SplitSelect}>
                  <div className={styles.position}>
                    <CustomLabel
                      label={'Choisir un statut'}
                    />
                    <CustomSearchableSelect
                      data={status}
                      placeholder={'Sélectionner un statut'}
                      setValue={setSelectedStatus}
                      defaultValue={selectedStatus}
                    />
                  </div>
                  <div className={styles.positionCheckbox}>
                    <BasicCheckbox
                      className={styles.test}
                      label={'branché'}
                      value={isPlugged}
                      setValue={setIsPlugged}
                      center
                    />
                  </div>
                </div>
              </div>
              :
              <div className={styles.SplitSelect}>
                <div className={styles.position}>
                  <div className={styles.Wrapper}>
                    <CustomLabel
                      label={'Emplacement/Quai'}
                    />
                    <CustomSearchableSelect
                      data={slots}
                      placeholder={'Sélectionner un emplacement/quai'}
                      setValue={setSelectedSlot}
                      defaultValue={selectedSlot}
                    />
                  </div>
                </div>
                <div className={styles.positionCheckbox}>
                  <BasicCheckbox
                    className={styles.test}
                    label={'branché'}
                    value={isPlugged}
                    setValue={setIsPlugged}
                    center
                  />
                </div>
              </div>
          }
          <BasicModalButton label={'Valider la mission'} onClick={() => {
            validateMoveRequest()
          }}
          />
        </>
      }
    </BaseModal>
  )
}

export default ValidateModal;