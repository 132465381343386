import styles from './Forms.module.scss';
import CustomLabel from '../label/CustomLabel';
import CustomSearchableSelect from '../select/CustomSearchableSelect/CustomSearchableSelect';
import { useCallback, useEffect, useState } from 'react';
import useAxiosApi from '../../hooks/useAxiosApi';
import { CONTAINER_REGEX, ICON_SIZE24, TRAILER_URL } from '../../constants';
import Card from 'react-bootstrap/Card';
import Button30 from '../button/Button30/Button30';
import MovingModal from '../modal/SearchTrailer/MovingModal';
import { FcSettings, FcViewDetails } from "react-icons/fc";
import EditModal from "../modal/SearchTrailer/EditModal";
import moment from "moment-timezone";
import TemperatureHistoryModal from "../modal/SearchTrailer/TemperatureHistoryModal";
import MovementHistoryModal from "../modal/SearchTrailer/MovementHistoryModal";
import toast from "react-hot-toast";

const FormSearch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const axiosApi = useAxiosApi();

  const [trailers, setTrailers] = useState('');
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  const [trailerData, setTrailerData] = useState(null)
  const [movingModalVisible, setMovingModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [temperatureModalVisible, setTemperatureModalVisible] = useState(false);
  const [movementModalVisible, setMovementModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetchTrailers = useCallback(async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    try {
      const result = await axiosApi.get(`${TRAILER_URL}/all`);
      setTrailers(result.data.list);
      setIsLoading(false);
      toast.remove(toastLoading);
    } catch (error) {
      toast.error('Une erreur est survenue', { id: toastLoading });
    }
  }, [axiosApi]);

  const fetchTrailerData = useCallback(async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    try {
      if (selectedTrailer) {
        const result = await axiosApi.post(`${TRAILER_URL}/num/data`, { selectedTrailer });
        setTrailerData(result.data.list);
        toast.remove(toastLoading);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error('Une erreur est survenue', { id: toastLoading });
      setIsLoading(false);
    }
  }, [axiosApi, selectedTrailer]);

  useEffect(() => {
    fetchTrailers();
  }, []);

  useEffect(() => {
    if (selectedTrailer) {
      fetchTrailerData();
    }
  }, [selectedTrailer]);

  useEffect(() => {
    if (refresh) {
      fetchTrailerData()
    }
  }, [refresh]);

  return (
    <div className={styles.Form}>
      {selectedTrailer === null &&
        <CustomLabel label={'Veuillez sélectionner un conteneur/remorque'}/>
      }

      <CustomLabel
        label={'N°Conteneur / Remorque'}
      />
      <div className={styles.Wrapper}>
        <CustomSearchableSelect
          data={trailers}
          defaultValue={selectedTrailer}
          placeholder={'ex: CAXU123456/7 ou AA-001-AA'}
          setValue={setSelectedTrailer}
          searchable
        />
      </div>

      {trailerData !== null
        &&
        <>
          <Card className={styles.Card}>
            <Card.Header as={'h2'} className={styles.CardHeader}>
              <div className={styles.IconLeftWrapper}>
                <FcViewDetails size={ICON_SIZE24} className={styles.Icon}/>
              </div>
              <div className={styles.CenterWrapper}>
                {CONTAINER_REGEX.test(trailerData.num_remorque) ? 'Conteneur ' : 'Remorque '}{trailerData.num_remorque}
              </div>

              {trailerData.id_statut !== 17 &&
                <div className={styles.IconRightWrapper}>
                  <FcSettings
                    size={ICON_SIZE24}
                    className={styles.Icon}
                    onClick={() => {
                      setEditModalVisible(!editModalVisible);
                    }}
                  />
                </div>
              }
            </Card.Header>
            <Card.Body className={styles.CardBody}>
              <Card.Title className={styles.CardTitle}>
                {CONTAINER_REGEX.test(trailerData.num_remorque) ? trailerData.id_type_remorque === 3 ? 'Détails conteneur sec' : 'Détails conteneur' : 'Détails remorque'}
              </Card.Title>
              <div className={styles.InnerCard}>
                <p className={styles.CardTextUnderlined}>Tractionnaire :</p>
                <p className={styles.CardText}>{trailerData.tractionnaire}</p>
              </div>
              {trailerData.id_type_remorque !== 3 &&
                <>
                  <div className={styles.InnerCard}>
                    <p className={styles.CardTextUnderlined}>Température théorique(°C) :</p>
                    <p className={styles.CardText}>{trailerData.temperature_theorique}°C</p>
                  </div>
                  <div className={styles.InnerCard}>
                    <p className={styles.CardTextUnderlined}>Dernière température :</p>
                    <p
                      className={styles.CardText}>{trailerData.temperature === null ? 'Aucune température relevé' : trailerData.temperature + '°C'}</p>
                  </div>
                </>
              }
              <div className={styles.InnerCard}>
                <p className={styles.CardTextUnderlined}>Statut :</p>
                <p
                  className={styles.CardText}>{trailerData.statut} / {trailerData.est_branche ? 'branché' : 'non branché'}</p>
              </div>
              <div className={styles.InnerCard}>
                <p className={styles.CardTextUnderlined}>Position actuelle :</p>
                <p className={styles.CardText}>
                  {trailerData.code_batiment === 'HP'
                    ? 'Hors du parc'
                    : <> {trailerData.code_batiment}-{trailerData.emplacement} </>
                  }
                </p>
              </div>
              {
                trailerData.date_arrivee &&
                <div className={styles.InnerCard}>
                  <p className={styles.CardTextUnderlined}>Date d'arrivée :</p>
                  <p className={styles.CardText}>
                    {moment(trailerData.date_arrivee.substring(0, 16)).format('DD/MM/YYYY HH:mm')}
                  </p>
                </div>
              }
            </Card.Body>
          </Card>
          <div className={styles.ButtonWrapper}>
            <Button30
              isLoading={isLoading}
              label={'Déplacer'}
              onClick={() => {
                setMovingModalVisible(!movingModalVisible);
              }}/>
            {trailerData.id_type_remorque !== 3 &&
              <Button30
                isLoading={isLoading}
                label={'Historique températures'}
                onClick={() => {
                  setTemperatureModalVisible(!movingModalVisible);
                }
                }/>
            }
            <Button30
              isLoading={isLoading}
              label={'Historique déplacements'}
              onClick={() => {
                setMovementModalVisible(!movingModalVisible);
              }}/>

          </div>
        </>
      }
      <MovingModal
        data={trailerData}
        modalVisible={movingModalVisible}
        setModalVisible={setMovingModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <EditModal
        data={trailerData}
        modalVisible={editModalVisible}
        setModalVisible={setEditModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <TemperatureHistoryModal
        data={trailerData}
        modalVisible={temperatureModalVisible}
        setModalVisible={setTemperatureModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <MovementHistoryModal
        data={trailerData}
        modalVisible={movementModalVisible}
        setModalVisible={setMovementModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </div>
  )
}

export default FormSearch;