import styles from './BasicCheckbox.module.scss';

const NotifyCheckbox = ({ label, value, setValue, center = false, disabled = true }) => {
  return (
    <div className={styles.NotifyCheckbox}>
      <input
        type={'checkbox'}
        name={'plugged'}
        checked={value}
        onChange={() => setValue(!value)}
        disabled={disabled}
      />
      <label htmlFor='label-checkbox'>{label}</label>
    </div>
  )
}

export default NotifyCheckbox;