import {
  API_URL,
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL, TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import { FcInspection } from 'react-icons/fc';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

const FullTrailerTransfertModal = ({ data, modalVisible, setModalVisible, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const sendNotification = async () => {
    return await axiosApi.get(`${API_URL}/notifications/new-mission`);
  }

  const changeStatus = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Changement de statut en cours...');
    await axiosApi.post(`${MOVINGREQUEST_URL}/check`,
      {
        id: data.id
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Demande de mission existante. Vous ne pouvez pas changer la statut de ce conteneur.' : 'Demande de mission existante. Vous ne pouvez pas changer la statut de cette remorque.', { id: toastLoading });
          setIsLoading(false);
        } else {
          await axiosApi.post(`${TRAILER_URL}/update/status`,
            {
              id: data.id,
              id_status: 4,
              isPlugged: 1,
              id_user: auth.id
            })
            .then(async (result) => {
              if (result.data.success) {
                toast.success('Changement de statut effectué', { id: toastLoading });
                if (result.data.success) {
                  const newMoveRequest = {
                    date_demande: null,
                    information_complementaire: 'Chaîne/transfert',
                    code_batiment: 'RO',
                    emplacement: 0,
                    id_remorque: data.id,
                    id_deplacement_statut: 4,
                    id_utilisateur: parseInt(auth.id),
                    est_branche: true
                  }

                  await axiosApi.post(`${MOVINGREQUEST_URL}/create`,
                    {
                      newMoveRequest
                    })
                    .then((result) => {
                      if (result.data.success) {
                        toast.success('Changement de statut effectuée.', { id: toastLoading });
                        sendNotification()
                          .then((result) => {
                            setIsLoading(false);
                            if (result.status === 201) {
                              console.log('Notification envoyé !');
                              toast('Notification envoyée aux chauffeurs de parcs.',
                                {
                                  icon: '👍',
                                });
                            } else if (result.status === 409) {
                              console.log('Aucun utilisateur à notifier');
                            } else {
                              console.log('Une erreur est survenue lors de l\'envoi de la notification');
                            }
                          });
                      } else {
                        toast.error('Erreur lors de la création de la mission!', { id: toastLoading });
                        setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      toast.error('Une erreur est survenue', { id: toastLoading });
                      setIsLoading(false);
                    });
                  setRefresh(true);
                } else {
                  toast.error('Une erreur est survenue lors du changement de statut', { id: toastLoading });
                  setIsLoading(false);
                }
                setRefresh(true);
              } else {
                toast.error('Une erreur est survenue lors du changement de statut', { id: toastLoading });
                setIsLoading(false);
              }
            })
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Plein en attente reprise</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.Wrapper}>
            <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
          </div>
          <div className={styles.Wrapper}>
            <label className={styles.ModalText}>Êtes-vous sûr de changer le statut : Plein en attente reprise ?</label>
          </div>
          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <ModalSuccessButton
              label={'Changer le statut'}
              onClick={() => {
                changeStatus();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default FullTrailerTransfertModal;