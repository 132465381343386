import { createContext, useState } from 'react';

export const CartContext = createContext({
  items: [],
  addToCart: () => {
  },
  deleteFromCart: () => {
  }
});

export const CartProvider = ({ children }) => {
  const [cartProducts, setCartProducts] = useState([]);

  const addToCart = (id, mission) => {
    sessionStorage.setItem(id, mission);
  }
  const deleteFromCart = (id) => {
    sessionStorage.removeItem(id);
  }

  const contextValue = {
    items: cartProducts,
    addToCart,
    deleteFromCart
  }
  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider;