import { customStylesTemperature, ICON_SIZE16, ICON_SIZE24, TRAILER_URL, } from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import { useEffect, useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import moment from "moment-timezone";
import LineTemperatureChart from "../../charts/LineTemperatureChart";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const TemperatureHistoryModal = ({
                                   data,
                                   modalVisible, setModalVisible,
                                   isLoading, setIsLoading,
                                 }) => {
  const axiosApi = useAxiosApi();

  const [historyTemperatures, setHistoryTemperatures] = useState('');

  const [userData, setUserData] = useState([]);
  const [labelChart, setLabelChart] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);

  const [dataChart, setDataChart] = useState({
    labels: labelChart,
    datasets: [{
      label: "Graphique température",
      data: temperatureData
    }]
  });
  let temperatureArray = [];
  let dateArray = [];

  const getTemperatureHistory = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    await axiosApi.post(`${TRAILER_URL}/history/temperature`,
      {
        id: data.id
      })
      .then((result) => {
        setHistoryTemperatures(result.data.list);
        result.data.list.forEach((record) => {
          temperatureArray.push(record.temperature);
          dateArray.push(moment(record.date.substring(0, 16)).format('DD/MM/YYYY HH:mm'));
        })
        setUserData(result.data.list);
        setLabelChart(dateArray);
        setTemperatureData(temperatureArray)
        toast.remove(toastLoading);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (temperatureData.length !== 0) {
      setDataChart({
        labels: labelChart,
        datasets: [
          {
            label: "Courbe température",
            data: temperatureData,
            backgroundColor: '#28A745',
            borderColor: '#28A745'
          },
          {
            label: "Température théorique",
            data: labelChart.map(() => data.temperature_theorique),
            borderColor: 'red',
            backgroundColor: 'red',
            pointRadius: 0
          },
        ],
      })
    }
  }, [temperatureData])
  useEffect(() => {
    if (modalVisible) {
      getTemperatureHistory();
    }
  }, [modalVisible])

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      style={customStylesTemperature}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label
              className={styles.HeaderText}>{`Historique température - ${data.num_remorque}`}</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          {temperatureData.length !== 0 ?
            <div className={styles.WrapperChart}>
              <LineTemperatureChart data={dataChart}/>
            </div>
            : 'Aucun historique de température n\'est disponible'
          }
        </>
      }
    </BaseModal>
  )
}

export default TemperatureHistoryModal;