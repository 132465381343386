import styles from '../Forms.module.scss';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import { useEffect, useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import { BUILDING_URL, STATUS_URL, TRAILER_URL } from '../../../constants';
import CustomParkText from '../../text/CustomParkText';
import CustomCenteredLabel from '../../label/CustomCenteredLabel';
import toast from "react-hot-toast";


const FormPark = () => {
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(status ? status[0] : null);
  const [buildings, setBuildings] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [trailers, setTrailers] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');

    await axiosApi.get(`${STATUS_URL}/trailer`)
      .then(async (result) => {
        if (result) {
          setStatus(result.data.list);
          await axiosApi.get(`${BUILDING_URL}/all`)
            .then((result) => {
              if (result) {
                setBuildings(result.data.list);
                setIsLoading(false);
                toast.remove(toastLoading);
              }
            })
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const getTrailers = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    let paramStatus = null;
    let paramBuilding = null;

    if (selectedStatus) {
      paramStatus = selectedStatus.value;
    }
    if (selectedBuilding) {
      paramBuilding = selectedBuilding.value;
    }

    await axiosApi.post(`${TRAILER_URL}/parkstate`,
      {
        status: paramStatus,
        code: paramBuilding
      })
      .then((result) => {
        if (result) {
          setTrailers(result.data.list);
          setIsLoading(false);
          toast.remove(toastLoading);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedStatus) {
      getTrailers();
    } else {
      if (selectedBuilding) {
        getTrailers();
      } else {
        setTrailers(null);
      }
    }
  }, [selectedStatus, refresh])

  useEffect(() => {
    if (selectedBuilding) {
      getTrailers();
    } else {
      if (selectedStatus) {
        getTrailers();
      } else {
        setTrailers(null);
      }
    }
  }, [selectedBuilding, refresh]);

  useEffect(() => {
    if (refresh) {
      fetchData()
        .then(() => {
          setRefresh(false);
        });
    }
  }, [refresh]);

  return (
    <div className={styles.Form}>
      {trailers === null &&
        <CustomLabel label={'Veuillez sélectionner un ou plusieurs filtres'}/>
      }
      <div className={styles.Wrapper}>
        <div className={styles.SplitSelect}>
          <div className={styles.position}>
            <CustomLabel
              label={'Bâtiment'}
            />
            <CustomSearchableSelect
              data={buildings}
              defaultValue={selectedBuilding}
              placeholder={'Sélectionner un bâtiment'}
              setValue={setSelectedBuilding}
              isClearable
            />
          </div>
          <div className={styles.position}>
            <CustomLabel
              label={'Statut'}
            />
            <CustomSearchableSelect
              data={status}
              defaultValue={selectedStatus}
              placeholder={'Sélectionner un statut'}
              setValue={setSelectedStatus}
              isClearable
            />
          </div>
        </div>
      </div>

      {trailers !== null
        &&
        <>
          <div className={styles.Wrapper}>
            {
              trailers !== null && trailers.length > 0 ?
                trailers.map((elem) => {
                  return (
                    <CustomParkText
                      data={elem}
                      key={elem.id}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      setRefresh={setRefresh}
                    />
                  )
                })
                :
                <CustomCenteredLabel label={'Aucun conteneur/remorque sur le bâtiment'}/>
            }
          </div>
        </>
      }
    </div>
  )
}

export default FormPark;