import useAxiosApi from '../../hooks/useAxiosApi';
import { useState } from 'react';
import styles from './CustomText.module.scss';
import { ICON_SIZE16, MOVINGREQUEST_URL } from '../../constants';
import OnGoingModal from '../modal/Mission/OnGoingModal';
import { FaArrowRight } from 'react-icons/fa';
import toast from "react-hot-toast";

const CustomOnGoingText = ({ data, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();

  const [dataDetails, setDataDetails] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);

  const getMoveRequestDetails = async (id) => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');

    await axiosApi.post(`${MOVINGREQUEST_URL}/details`,
      {
        id
      })
      .then((result) => {
        setDataDetails(result.data.list);
        setModalVisible(true);
        setIsLoading(false);
        toast.remove(toastLoading);
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className={styles.Wrapper} onClick={async () => {
        await getMoveRequestDetails(data.id)
      }}>
        <label className={styles.TextBold}>{data.prenom} - {data.num_remorque}</label>
        <label className={styles.Text}>{data.code_batiment_actuel}-{data.emplacement_actuel} {<FaArrowRight
          size={ICON_SIZE16}
          className={styles.ArrowIcon}/>} {data.code_batiment_demande}-{data.emplacement_demande}</label>
      </div>
      <OnGoingModal
        data={dataDetails}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setRefresh={setRefresh}
      />
    </>
  )
}

export default CustomOnGoingText;