import styles from './Label.module.scss';

const CustomIconLabel = ({ label }) => {
  return (
    <div className={styles.CustomIconLabel}>
      <label className={styles.IconLabel}>{label}</label>
    </div>
  )
}

export default CustomIconLabel;