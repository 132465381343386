import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Graphique température',
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date'
      },
      ticks: {
        stepSize:5,
        autoSkipPadding: 5,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Température(°C)'
      },
      // reverse: true,
      offset: true,
      suggestedMin: -20,
      suggestedMax: 5,
      ticks: {
        stepSize:5,
      },
    }
  }
};
const LineTemperatureChart = ({ data }) => {
  return (
    <Line data={data} options={options}/>
  )
}

export default LineTemperatureChart;