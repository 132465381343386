import {
  ACTION_URL,
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import { FcInspection } from 'react-icons/fc';
import Button60 from '../../button/Button60/Button60';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const ValidateOnGoingActionModal = ({ data, modalVisible, setModalVisible, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();

  const validateAction = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Validation de l\'action en cours...');

    await axiosApi.post(`${ACTION_URL}/update`,
      {
        id: data.id,
        id_statut: 20
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('Validation de l\'action effectuée', { id: toastLoading });
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de l\'annulation', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Valider action</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>

              : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            }
          </div>
          <div className={styles.Wrapper}>
            <label className={styles.ModalText}>Êtes-vous sûr de valider l'action ?</label>
          </div>

          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <Button60
              label={'Valider'}
              onClick={() => {
                validateAction();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default ValidateOnGoingActionModal;