import { Button, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import styles from './CustomButtonGroup.module.scss';

const CustomButtonGroup = ({ setSelectedTrailerType }) => {
  const [buttonTrailerCss, setButtonTrailerCss] = useState('outlined');
  const [buttonContainerCss, setButtonContainerCss] = useState('contained');
  return (
    <ButtonGroup className={styles.ButtonGroup} fullWidth={true}>
      <Button
        color='success'
        variant={buttonTrailerCss}
        onClick={() => {
          setSelectedTrailerType(0);
          setButtonContainerCss('outlined')
          setButtonTrailerCss('contained');
        }}>Remorque</Button>
      <Button
        color='success'
        variant={buttonContainerCss}
        onClick={() => {
          setSelectedTrailerType(1);
          setButtonTrailerCss('outlined')
          setButtonContainerCss('contained');
        }}>Conteneur</Button>
    </ButtonGroup>

  )
}

export default CustomButtonGroup;