import {
  ICON_SIZE16,
  ICON_SIZE24, TEMPERATURE_REGEX, TRAILER_URL
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import { FcInspection } from 'react-icons/fc';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import { isDatePast } from "../../../utils/functions";
import { render } from "@react-email/render";
import TemperatureAlertsEmail from "../../mail/TemperatureAlertsEmail";
import useAuth from "../../../hooks/useAuth";

const TemperatureConfirmationModal = ({
                                        data,
                                        mainModal, setMainModal,
                                        modalVisible, setModalVisible,
                                        temperature,
                                        isLoading, setIsLoading,
                                        setRefresh
                                      }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const sendModifications = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Modification en cours...');
    if (temperature === '' || !temperature) {
      toast.error('Veuillez sélectionner une température', { id: toastLoading });
      setIsLoading(false)
      return;
    }
    if (!TEMPERATURE_REGEX.test(temperature)) {
      toast.error('Veuillez entrer une température valide', { id: toastLoading });
      setIsLoading(false)
      return;
    }

    const theoreticalTemperature = parseFloat(data.temperature_theorique);
    const selectedTemp = parseFloat(temperature);
    const lowerLimit = theoreticalTemperature * 0.9;
    const upperLimit = theoreticalTemperature * 1.1;

    let emailHtml = '';
    const isPast4hours = isDatePast(data.date_arrivee);

    if(selectedTemp < 0){
      if (isPast4hours && (selectedTemp > lowerLimit || selectedTemp < upperLimit)) {
        emailHtml = render(<TemperatureAlertsEmail container={data.label} temperature={selectedTemp}
                                                   temperature_theorique={theoreticalTemperature}/>);
      }
    }else {
      if (isPast4hours && (selectedTemp < lowerLimit || selectedTemp > upperLimit)) {
        emailHtml = render(<TemperatureAlertsEmail container={data.label} temperature={selectedTemp}
                                                   temperature_theorique={theoreticalTemperature}/>);
      }
    }

    const trailer = {
      id_remorque: data.id,
      id_utilisateur: auth.id,
      id_statut: data.id_statut,
      code_batiment: data.code_batiment,
      temperature: temperature,
      html: emailHtml
    }

    await axiosApi.post(`${TRAILER_URL}/update/temperature`,
      {
        trailer
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('La température a bien été modifiée.', { id: toastLoading });
          if(result.data.message) toast.success(result.data.message);
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de la modification de température', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
    setIsLoading(false);
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Confirmation température</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
              setMainModal(!mainModal);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          {/*<div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>

              : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            }
          </div>*/}
          <div className={styles.Wrapper}>
            <label className={styles.ModalText}>Température anormale, une notification sera envoyé aux bureaux.
              Êtes-vous sûr de vouloir continuer ?</label>
          </div>

          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <ModalSuccessButton
              label={'Valider température'}
              onClick={() => {
                sendModifications();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default TemperatureConfirmationModal;