import CustomInput from '../input/CustomInput';
import { useEffect, useState } from 'react';
import CustomLabel from '../label/CustomLabel';
import CustomButtonGroup from '../button/ButtonGroup/CustomButtonGroup';
import CustomSearchableSelect from '../select/CustomSearchableSelect/CustomSearchableSelect';
import styles from './Forms.module.scss';
import BasicCheckbox from '../checkbox/BasicCheckbox';
import BasicButton from '../button/BasicButton/BasicButton';
import {
  BUILDING_URL, CONTAINER_REGEX, DATE_REGEX, EMPTY_REGEX, FULL_REGEX,
  SLOT_URL,
  STATUS_URL,
  TEMPERATURE_REGEX, TIME_REGEX,
  TRACTIONIST_URL,
  TRAILER_REGEX,
  TRAILER_URL
} from '../../constants';
import useAxiosApi from '../../hooks/useAxiosApi';
import { formatDate, getSelected } from '../../utils/functions';
import useAuth from "../../hooks/useAuth";
import DatePicker from "react-datepicker";
import { render } from '@react-email/render';
import ArrivalContainerEmail from "../mail/ArrivalContainerEmail";
import toast from "react-hot-toast";
import NotifyCheckbox from "../checkbox/NotifyCheckbox";

const FormArrival = () => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [tractionists, setTractionists] = useState('')
  const [buildings, setBuildings] = useState('');
  const [slots, setSlots] = useState('');
  const [status, setStatus] = useState('')
  const [container, setContainer] = useState('');
  const [prevContainer, setPrevContainer] = useState('');
  const [selectedTrailerType, setSelectedTrailerType] = useState(1);
  const [selectedTraco, setSelectedTraco] = useState('');
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [selectedTheoreticalTemperature, setSelectedTheoreticalTemperature] = useState(-20);
  const [selectedCurrentTemperature, setSelectedCurrentTemperature] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [date, setDate] = useState(new Date());
  const [isDry, setIsDry] = useState(false);
  const [isPlugged, setIsPlugged] = useState(true);
  const [notification, setNotification] = useState(false);
  const [disableNotify, setDisableNotify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement des données...');
    try {
      const tractionistResult = await axiosApi.get(`${TRACTIONIST_URL}/all`);
      setTractionists(tractionistResult.data.list);
      const buildingResult = await axiosApi.get(`${BUILDING_URL}/all`);
      setBuildings(buildingResult.data.list);
      setSelectedBuilding(getSelected(buildingResult.data.list, 'R')[0]);
      const statusResult = await axiosApi.get(`${STATUS_URL}/trailer`);
      setStatus(statusResult.data.list);
      setSelectedStatus(getSelected(statusResult.data.list, 5)[0]);
      toast.remove(toastLoading);
    } catch (err) {
      toast.error('Une erreur est survenue lors du chargement des données', { id: toastLoading });
    } finally {
      setIsLoading(false);
    }
  }
  const validateTrailer = (newTrailer) => {
    if (newTrailer.num_remorque === '') {
      return 'Veuillez entrer un numéro de conteneur/remorque';
    }
    if (!CONTAINER_REGEX.test(newTrailer.num_remorque) && !TRAILER_REGEX.test(newTrailer.num_remorque)) {
      return 'Veuillez entrer un numéro de conteneur/remorque valide';
    }
    if (CONTAINER_REGEX.test(newTrailer.num_remorque) && selectedTrailerType === 0) {
      return 'Vous ne pouvez pas appliquer le type remorque à un numéro conteneur';
    }
    if (TRAILER_REGEX.test(newTrailer.num_remorque) && selectedTrailerType === 1) {
      return 'Vous ne pouvez pas appliquer le type conteneur à un numéro de remorque';
    }
    if (newTrailer.id_tractionnaire === '' || newTrailer.id_tractionnaire === undefined) {
      return 'Veuillez sélectionner un tractionnaire';
    }
    if (newTrailer.code_batiment === '' || newTrailer.code_batiment === undefined) {
      return 'Veuillez entrer un bâtiment';
    }
    if (newTrailer.emplacement === '' || newTrailer.emplacement === undefined) {
      return 'Veuillez entrer un emplacement';
    }
    if (newTrailer.temperature_theorique === '') {
      return 'Veuillez entrer une température théorique';
    }
    if (!TEMPERATURE_REGEX.test(newTrailer.temperature_theorique)) {
      return 'Veuillez entrer une température théorique valide';
    }
    if (newTrailer.id_statut === '' || newTrailer.id_statut === undefined) {
      return 'Veuillez sélectionner un statut';
    }
    return null;
  };

  const createTrailer = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Enregistrement en cours...');
    let fDate = '';
    let fTime = '';

    if (date !== null) {
      fDate = ('0' + date.getUTCDate()).slice(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '/' + date.getFullYear();
      fTime = ('0' + date.getHours()).slice(-2) + 'h' + ('0' + date.getMinutes()).slice(-2);
    }
    if (fDate === '' || fTime === '') {
      setIsLoading(false);
      toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
      return;
    }
    if (!DATE_REGEX.test(fDate)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une date valable', { id: toastLoading });
      return;
    }
    if (!TIME_REGEX.test(fTime)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une heure valable', { id: toastLoading });
      return;
    }

    let emailHtml = '';
    if(notification){
      emailHtml = render(<ArrivalContainerEmail container={container} status={selectedStatus.label}
                                                temperature={selectedTheoreticalTemperature}
                                                tractionnist={selectedTraco.label} isDry={isDry}
                                                code_batiment={selectedBuilding.id}
                                                emplacement={selectedSlot.id}/>);
    }

    const newTrailer = {
      num_remorque: container.toUpperCase(),
      temperature_theorique: selectedTheoreticalTemperature,
      temperature: selectedCurrentTemperature,
      est_branche: isPlugged,
      id_statut: selectedStatus.id,
      id_tractionnaire: selectedTraco.id,
      id_type_remorque: selectedTrailerType,
      code_batiment: selectedBuilding.id,
      emplacement: selectedSlot.id,
      id_user: auth.id,
      date_arrivee: formatDate(fDate, fTime),
      html: emailHtml,
    }

    const errorMessage = validateTrailer(newTrailer);
    if (errorMessage) {
      setIsLoading(false);
      toast.error(errorMessage, { id: toastLoading });
      return;
    }

    if (isDry) {
      newTrailer.id_type_remorque = 3;
    }

    try {
      if (newTrailer.emplacement !== 0) {
        const { data } = await axiosApi.post(`${TRAILER_URL}/check/position`,
          {
            code_batiment: selectedBuilding.id,
            emplacement: selectedSlot.id
          });

        if (data.success) {
          setIsLoading(false);
          toast.error('Cet emplacement est déjà occupé', { id: toastLoading });
          return;
        }
      }

      const { data } = await axiosApi.post(`${TRAILER_URL}/num`,
        {
          newTrailer
        });

      if (!data.success) {
        setIsLoading(false);
        toast.error(CONTAINER_REGEX.test(newTrailer.num_remorque) ? 'Conteneur déjà existant' : 'Remorque déjà existante', { id: toastLoading });
        return;
      }
      const result = await axiosApi.post(`${TRAILER_URL}/create`, {
        newTrailer
      });

      if (result.data.success) {
        setIsLoading(false);
        toast.success('Ajout effectué !', { id: toastLoading });
      } else {
        setIsLoading(false);
        toast.error('Erreur lors de la création !', { id: toastLoading });
      }
    } catch
      (error) {
      setIsLoading(false);
      toast.error(error.message || 'Erreur lors de la création !', { id: toastLoading });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (selectedBuilding !== '') {
      const toastLoading = toast.loading('Chargement en cours...');
      axiosApi.post(`${SLOT_URL}/code`,
        {
          code: selectedBuilding.value
        })
        .then((result) => {
          setSlots(result.data.list);
          setSelectedSlot(getSelected(result.data.list, 0)[0]);
          toast.remove(toastLoading);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('ERROR ', err);
          toast.error('Une erreur est survenue', { id: toastLoading });
        });
    }
  }, [selectedBuilding])

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(false);
      }
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (selectedTrailerType === 0) {
      if (selectedTraco?.value === 14) {
        setIsDry(false);
        setDisabledCheckbox(false);
        setDisabledInput(false);
      } else {
        setIsDry(false);
        setDisabledCheckbox(true);
        setDisabledInput(false);
      }
    } else {
      setDisabledCheckbox(false);
    }
  }, [selectedTrailerType, selectedTraco])

  useEffect(() => {
    if (isDry) {
      setDisabledInput(true);
      setSelectedTheoreticalTemperature(0);
    } else {
      setDisabledInput(false);
    }
  }, [isDry])

  useEffect(() => {
      if ((!prevContainer.endsWith("/") || !prevContainer.endsWith("-")) && container.length > prevContainer.length) {
        if (container.length === 10 && selectedTrailerType === 1) {

          setContainer(container + '/');
        }
        if ((container.length === 2 || container.length === 6) && selectedTrailerType === 0) {
          setContainer(container + '-');
        }
      }
      setPrevContainer(container);
    }
    , [container]);

  useEffect(() => {
    if((selectedStatus.label === 'Plein en attente de consigne' || selectedStatus.label === 'Vide à empoter') && selectedTrailerType === 1){
      setDisableNotify(false);
      setNotification(true);
    }else {
      setDisableNotify(true);
      setNotification(false);
    }
  }, [selectedStatus, selectedTrailerType]);


  return (
    <div className={styles.Form}>
      <div className={styles.Wrapper}>
        <CustomLabel
          label={'N°Conteneur / Remorque'}
        />
        <div className={styles.TextWrapper}>
          <CustomInput
            value={container}
            setValue={setContainer}
            placeholder={'ex: CAXU123456/7 ou AA-001-AA'}
            maxLength={selectedTrailerType === 0 ? 9 : 12}
          />
        </div>
      </div>

      <CustomLabel
        label={'Choisir le type de remorque'}
      />
      <CustomButtonGroup
        setSelectedTrailerType={setSelectedTrailerType}
      />

      <div className={styles.Wrapper}>
        <CustomLabel
          label={'Tractionnaire'}
        />
        <CustomSearchableSelect
          data={tractionists}
          placeholder={'Sélectionner un tractionnaire'}
          setValue={setSelectedTraco}
          defaultValue={selectedTraco}
        />
      </div>
      <div className={styles.SplitSelect}>
        <div className={styles.position}>
          <CustomLabel
            label={'Date d\'arrivée'}
          />
          <DatePicker
            selected={date}
            onChange={(d) => setDate(d)}
            timeInputLabel='Heure:'
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeInput
            locale='fr'
            customInput={<input className={styles.DatePicker} type='text' value={date}/>}
          />
        </div>
      </div>

      <div className={styles.SplitSelect}>
        <div className={styles.position}>
          <div className={styles.Wrapper}>
            <CustomLabel
              label={'Bâtiment'}
            />
            <CustomSearchableSelect
              data={buildings}
              placeholder={'Sélectionner un bâtiment'}
              setValue={setSelectedBuilding}
              defaultValue={selectedBuilding}
            />
          </div>
        </div>
        <div className={styles.position}>
          <div className={styles.Wrapper}>
            <CustomLabel
              label={'Emplacement/Quai'}
            />
            <CustomSearchableSelect
              data={slots}
              placeholder={'Sélectionner un emplacement/quai'}
              setValue={setSelectedSlot}
              defaultValue={selectedSlot}
            />
          </div>
        </div>
      </div>

      <div className={styles.SplitSelectColumn}>
        <CustomLabel
          label={'Température théorique(°C)'}
        />
        <div className={styles.TextWrapper}>
          <div className={styles.position}>
            <div className={styles.Wrapper}>
              <div className={styles.TextWrapper}>
                <CustomInput
                  value={selectedTheoreticalTemperature}
                  setValue={setSelectedTheoreticalTemperature}
                  placeholder={'ex: -20'}
                  maxLength={5}
                  disabled={disabledInput}
                />
              </div>
            </div>
          </div>
          <div className={styles.position}>
            <div className={styles.Wrapper}>
              <BasicCheckbox
                label={'Conteneur sec'}
                value={isDry}
                setValue={setIsDry}
                center
                disabled={disabledCheckbox}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Wrapper}>
        <CustomLabel
          label={'Choisir un statut'}
        />
        <CustomSearchableSelect
          data={status}
          placeholder={'Sélectionner un statut'}
          setValue={setSelectedStatus}
          defaultValue={selectedStatus}
        />
      </div>

      <div className={styles.WrapperRow}>
        <BasicCheckbox
          label={'branché'}
          value={isPlugged}
          setValue={setIsPlugged}
        />
      </div>

      <div className={styles.WrapperArrivalCenter}>
        <BasicButton
          label={'Valider arrivée'}
          onClick={() => {
            createTrailer()
          }}
          isLoading={isLoading}
        />
        <NotifyCheckbox
          label={'Notifier'}
          value={notification}
          setValue={setNotification}
          disabled={disableNotify}
        />
      </div>
    </div>
  )
}

export default FormArrival;