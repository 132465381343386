import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_URL } from '../constants';
import { api } from '../api/axios';
import CookieService from '../services/CookieService';
import LoginLayout from '../components/templates/LoginLayout/LoginLayout';
import CustomLabel from '../components/label/CustomLabel';
import CustomInput from '../components/input/CustomInput';
import BasicButton from '../components/button/BasicButton/BasicButton';
import styles from '../styles/global.module.scss';
import toast from "react-hot-toast";

const LoginScreen = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    const toastLoading = toast.loading('Connexion...');
    try {
      api.post(`${LOGIN_URL}`, {
        email: login, password
      })
        .then(async (result) => {
          if (result.data.success) {

            const { user, token, refreshToken } = result.data;
            const oneDayExpiration = 24 * 60 * 60;

            let dateAT = new Date();
            let dateRT = new Date();

            dateAT.setTime(dateAT.getTime() + (15 * 60 * 1000));
            dateRT.setTime(dateRT.getTime() + (oneDayExpiration * 7 * 1000));

            const optionsAT = { path: '/', expires: dateAT }
            const optionsRT = { path: '/', expires: dateRT }

            CookieService.set('access_token', token, optionsAT);
            CookieService.set('refresh_token', refreshToken, optionsRT);

            setAuth({ token: token, email: user.email, firstname: user.firstname, id: user.id, role: user.role });
            setLogin('');
            setPassword('');
            navigate(from, { replace: true });
            setIsloading(false);
            toast.success('Bonjour ' + user.firstname + ' !', { id: toastLoading });
          } else {
            toast.error(result.data.message, { id: toastLoading });
            setIsloading(false);
          }
        })
        .catch(err => {
          toast.error('Une erreur est survenue(1)', { id: toastLoading });
          console.log(err.message);
          setIsloading(false);
        })

    } catch (err) {
      toast.error('Une erreur est survenue(2)', { id: toastLoading });
      console.log(err);
      setIsloading(false);
    }
  }
  return (
    <>
      <LoginLayout>
        <CustomLabel
          label={'Identifiant'}
        />
        <div className={styles.TextWrapper}>
          <CustomInput
            value={login}
            setValue={setLogin}
            placeholder={'ex: k.mbappe'}
          />
        </div>
        <CustomLabel
          label={'Mot de passe'}
        />
        <div className={styles.TextWrapper}>
          <CustomInput
            value={password}
            setValue={setPassword}
            placeholder={'Entrer un mot de passe'}
            type={'password'}
          />
        </div>
        <BasicButton
          isLoading={isLoading}
          label={'Se connecter'}
          onClick={(e) => {
            handleSubmit(e)
          }}
        />
      </LoginLayout>
    </>
  );
};

export default LoginScreen;