import styles from './Forms.module.scss';
import CustomLabel from '../label/CustomLabel';
import { useRef, useState } from 'react';
import useAxiosApi from '../../hooks/useAxiosApi';

import Button30 from '../button/Button30/Button30';

import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import { DATE_REGEX, STATS_URL, TIME_REGEX } from "../../constants";
import { formatDate, getFormatedDate } from "../../utils/functions";

const FormExport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const axiosApi = useAxiosApi();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [temperature, setTemperature] = useState([]);
  const [moving, setMoving] = useState([]);
  const csvLinkTemperature = useRef(null);
  const csvLinkMoving = useRef(null);

  const headersTemperature = [
    { label: "Id", key: "id" },
    { label: "Numéro", key: "num_remorque" },
    { label: "Date", key: "temperature_date" },
    { label: "Heure", key: "temperature_heure" },
    { label: "Température", key: "temperature" },
    { label: "Utilisateur", key: "nom_complet" }
  ];

  const headersMoving = [
    { label: "Id", key: "id" },
    { label: "Numéro", key: "num_remorque" },
    { label: "Bâtiment", key: "code_batiment" },
    { label: "Emplacement", key: "emplacement" },
    { label: "Date", key: "date_deplacement" },
    { label: "Heure", key: "heure_deplacement" },
    { label: "Utilisateur", key: "nom_complet" }
  ];
  const getUserList = () => {
    return fetch('https://jsonplaceholder.typicode.com/users')
      .then(res => res.json());
  }
  const getTemperatures = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Requête en cours...');
    let fDateStart = '';
    let fTimeStart = '';
    let fDateEnd = '';
    let fTimeEnd = '';

    if (startDate !== null) {
      fDateStart = ('0' + startDate.getUTCDate()).slice(-2) + '/' + ('0' + (startDate.getUTCMonth() + 1)).slice(-2) + '/' + startDate.getFullYear();
      fTimeStart = ('0' + startDate.getHours()).slice(-2) + 'h' + ('0' + startDate.getMinutes()).slice(-2);
    }
    if (fDateStart === '' || fTimeStart === '') {
      setIsLoading(false);
      toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
      return;
    }
    if (!DATE_REGEX.test(fDateStart)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une date valable', { id: toastLoading });
      return;
    }
    if (!TIME_REGEX.test(fTimeStart)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une heure valable', { id: toastLoading });
      return;
    }

    if (endDate !== null) {
      fDateEnd = ('0' + endDate.getUTCDate()).slice(-2) + '/' + ('0' + (endDate.getUTCMonth() + 1)).slice(-2) + '/' + endDate.getFullYear();
      fTimeEnd = ('0' + endDate.getHours()).slice(-2) + 'h' + ('0' + endDate.getMinutes()).slice(-2);
    }
    if (fDateEnd === '' || fTimeEnd === '') {
      setIsLoading(false);
      toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
      return;
    }
    if (!DATE_REGEX.test(fDateEnd)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une date valable', { id: toastLoading });
      return;
    }
    if (!TIME_REGEX.test(fTimeEnd)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une heure valable', { id: toastLoading });
      return;
    }

    try {
      const result = await axiosApi.post(`${STATS_URL}/temperature`, {
        date_debut: formatDate(fDateStart, fTimeStart),
        date_fin: formatDate(fDateEnd, fTimeEnd)
      });
      if (result.data.success) {
        setIsLoading(false);
        toast.success('Récupération réussis !', { id: toastLoading });
        return result.data.list;
      } else {
        setIsLoading(false);
        toast.error('Erreur lors de la requête !', { id: toastLoading });
      }
    } catch
      (error) {
      setIsLoading(false);
      toast.error(error.message || 'Erreur lors de la requête !', { id: toastLoading });
    }
  }
  const getMovings = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Requête en cours...');
    let fDateStart = '';
    let fTimeStart = '';
    let fDateEnd = '';
    let fTimeEnd = '';

    if (startDate !== null) {
      fDateStart = ('0' + startDate.getUTCDate()).slice(-2) + '/' + ('0' + (startDate.getUTCMonth() + 1)).slice(-2) + '/' + startDate.getFullYear();
      fTimeStart = ('0' + startDate.getHours()).slice(-2) + 'h' + ('0' + startDate.getMinutes()).slice(-2);
    }
    if (fDateStart === '' || fTimeStart === '') {
      setIsLoading(false);
      toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
      return;
    }
    if (!DATE_REGEX.test(fDateStart)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une date valable', { id: toastLoading });
      return;
    }
    if (!TIME_REGEX.test(fTimeStart)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une heure valable', { id: toastLoading });
      return;
    }

    if (endDate !== null) {
      fDateEnd = ('0' + endDate.getUTCDate()).slice(-2) + '/' + ('0' + (endDate.getUTCMonth() + 1)).slice(-2) + '/' + endDate.getFullYear();
      fTimeEnd = ('0' + endDate.getHours()).slice(-2) + 'h' + ('0' + endDate.getMinutes()).slice(-2);
    }
    if (fDateEnd === '' || fTimeEnd === '') {
      setIsLoading(false);
      toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
      return;
    }
    if (!DATE_REGEX.test(fDateEnd)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une date valable', { id: toastLoading });
      return;
    }
    if (!TIME_REGEX.test(fTimeEnd)) {
      setIsLoading(false);
      toast.error('Veuillez entrer une heure valable', { id: toastLoading });
      return;
    }

    try {
      const result = await axiosApi.post(`${STATS_URL}/moving`, {
        date_debut: formatDate(fDateStart, fTimeStart),
        date_fin: formatDate(fDateEnd, fTimeEnd)
      });
      if (result.data.success) {
        setIsLoading(false);
        toast.success('Récupération réussis !', { id: toastLoading });
        return result.data.list;
      } else {
        setIsLoading(false);
        toast.error('Erreur lors de la requête !', { id: toastLoading });
      }
    } catch
      (error) {
      setIsLoading(false);
      toast.error(error.message || 'Erreur lors de la requête !', { id: toastLoading });
    }
  }

  const downloadTemperature = async () => {
    const temperature = await getTemperatures();
    setTemperature(temperature);
    setTimeout(() => {
      csvLinkTemperature.current.link.click();
      toast('CSV téléchargé !', { icon: '📥' });
    });
  }
  const downloadMoving = async () => {
    const movings = await getMovings();
    setMoving(movings);
    setTimeout(() => {
      csvLinkMoving.current.link.click();
      toast('CSV téléchargé !', { icon: '📥' });
    });
  }

  return (
    <div className={styles.Form}>
      <div className={styles.SplitSelect}>
        <div className={styles.position}>
          <CustomLabel
            label={'Date de début'}
          />
          <DatePicker
            selected={startDate}
            onChange={(d) => setStartDate(d)}
            timeInputLabel='Heure:'
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeInput
            locale='fr'
            customInput={<input className={styles.DatePicker} type='text' value={startDate}/>}
          />
        </div>
        <div className={styles.position}>
          <CustomLabel
            label={'Date de fin'}
          />
          <DatePicker
            selected={endDate}
            onChange={(d) => setEndDate(d)}
            timeInputLabel='Heure:'
            dateFormat='dd/MM/yyyy HH:mm'
            showTimeInput
            locale='fr'
            customInput={<input className={styles.DatePicker} type='text' value={endDate}/>}
          />
        </div>
      </div>
      <div className={styles.ButtonWrapper}>
        <Button30
          label={'Export déplacement'}
          onClick={() => {
            downloadMoving();
          }}
          isLoading={isLoading}
        />
        <CSVLink
          headers={headersMoving}
          filename={"export_deplacement " + getFormatedDate(startDate) + "-" + getFormatedDate(endDate) + ".csv"}
          data={moving}
          ref={csvLinkMoving}
        />
        <CSVLink
          headers={headersTemperature}
          filename={"export_temperature" + getFormatedDate(startDate) + "-" + getFormatedDate(endDate) + ".csv"}
          data={temperature}
          ref={csvLinkTemperature}
        />
        <Button30
          label={'Export temperature'}
          onClick={() => {
            downloadTemperature();
          }}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default FormExport;