import { ICON_SIZE40 } from '../../constants';
import Widget from '../../components/widget/Widget';
import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import { GiTruck } from 'react-icons/gi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaWarehouse } from "react-icons/fa";
import styles from "../../styles/global.module.scss";

const ParkHome = () => {
  return (
    <DefaultLayout title={'État du parc'}>
      <div className={styles.WidgetWrapper}>
        <Widget
          path={'global'}
          icon={<AiOutlineGlobal size={ICON_SIZE40}/>}
          title_1={'Suivi global'}
          title_2={''}
        />
        <Widget
          path={'tractionnist'}
          icon={<GiTruck size={ICON_SIZE40}/>}
          title_1={'Suivi'}
          title_2={'tractionnaire'}
        />
        <Widget
          path={'building'}
          icon={<FaWarehouse size={ICON_SIZE40}/>}
          title_1={'Suivi'}
          title_2={'bâtiment'}
        />
      </div>
    </DefaultLayout>
  );
};

export default ParkHome;