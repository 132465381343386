import styles from '../Modal.module.scss';
import {
  CONTAINER_REGEX,
  ICON_SIZE14,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL,
} from '../../../constants';

import { ImCross } from 'react-icons/im';
import { FaArrowRight } from 'react-icons/fa';
import { useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import useAuth from '../../../hooks/useAuth';
import { FcViewDetails } from 'react-icons/fc';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import CancelModal from './CancelModal';
import ModalSuccessButton from "../../button/ModalButton/ModalSuccessButton";
import ModalModifyButton from "../../button/ModalButton/ModalModifyButton";
import ModifyModal from "./ModifyModal";
import Button60 from "../../button/Button60/Button60";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const moment = require('moment-timezone');

const ToDoModal = ({ data, modalVisible, setModalVisible, setRefresh }) => {
  const { auth } = useAuth()
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);

  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);

  const updatingMoveRequest = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Mise à jours en cours...');
    await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
      {
        id: data.id,
        id_statut: 19,
        id_utilisateur: auth.id
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('La mission à été pris en compte avec succès !', { id: toastLoading });
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Erreur', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <>
      <BaseModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        {data !== null &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Détail de la mission</label>
              <div onClick={() => {
                setIsLoading(false);
                setModalVisible(!modalVisible);
              }}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>
                {CONTAINER_REGEX.test(data.num_remorque)
                  ? <label className={styles.ModalText}>Conteneur :</label>

                  : <label className={styles.ModalText}>Remorque :</label>
                }
              </label>
              <div>
                <label className={styles.ModalTextBold}>
                  {data.num_remorque}
                </label>
                : {data.code_batiment_actuel}-{data.emplacement_actuel}
                {<FaArrowRight size={ICON_SIZE14} className={styles.ArrowIcon}/>}
                {data.code_batiment_demande}-{data.emplacement_demande} {moment(data.date_demande.substring(0, 16)).format('HH[h]')}
              </div>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Tractionnaire :</label>
              <label className={styles.ModalText}>{data.libelle}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Demandeur :</label>
              <label
                className={styles.ModalText}> {data.prenom} le {moment(data.date_creation.substring(0, 16)).format('DD/MM/YYYY [à] HH[h]mm')}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Informations compl. :</label>
              <label
                className={styles.ModalText}>{data.information_complementaire !== '' ? data.information_complementaire : 'Aucunes informations'}</label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>N°de quai :</label>
              <label
                className={styles.ModalText}>{data.emplacement_demande !== '' ? data.emplacement_demande : 'Inconnu'}</label>
            </div>
            <div className={styles.ButtonWrapper}>
              {(auth.role === 2 || auth.role === 3 || auth.role === 4) &&
                <>
                  <ModalCancelButton
                    label={'Supprimer'}
                    onClick={() => {
                      setModalVisible(!modalVisible);
                      setCancelModalVisible(!cancelModalVisible)
                    }}
                    isLoading={isLoading}
                  />
                  <ModalModifyButton
                    label={'Modifier'}
                    onClick={() => {
                      setModalVisible(!modalVisible);
                      setModifyModalVisible(!modifyModalVisible)
                    }}
                    isLoading={isLoading}
                  />
                </>
              }
              {(auth.role === 1) &&
                <Button60
                  label={'Confirmer la prise en compte'}
                  onClick={() => {
                    updatingMoveRequest();
                  }}
                  isLoading={isLoading}
                />
              }
              {(auth.role === 3 || auth.role === 4) &&
                <ModalSuccessButton
                  label={'Confirmer la prise en compte'}
                  onClick={() => {
                    updatingMoveRequest();
                  }}
                  isLoading={isLoading}
                />
              }
            </div>
          </>
        }
      </BaseModal>
      <CancelModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={cancelModalVisible}
        setModalVisible={setCancelModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <ModifyModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={modifyModalVisible}
        setModalVisible={setModifyModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </>

  )
}

export default ToDoModal;