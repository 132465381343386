import {
  customStyles
} from '../../constants';
import Modal from 'react-modal';
import styles from "./Modal.module.scss";
import Spinner from "../spinner/Spinner";

const BaseModal = ({
                     children,
                     modalVisible,
                     setModalVisible,
                     style = customStyles
                   }) => {

  return (
    <Modal
      isOpen={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}
      ariaHideApp={false}
      style={style}>
      {children}
    </Modal>
  )
}

export default BaseModal;