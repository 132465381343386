export const API_URL =
  process.env.REACT_APP_API_URL_PROD ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_DEFAULT;
export const APP_URL =
  process.env.REACT_APP_APP_URL_PROD ? process.env.REACT_APP_APP_URL_PROD
    : process.env.REACT_APP_APP_URL_DEV ? process.env.REACT_APP_APP_URL_DEV
      : process.env.REACT_APP_APP_URL_DEFAULT;

export const LOGIN_URL = `${API_URL}/auth/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const REFRESH_URL = `${API_URL}/refresh`;
export const TRACTIONIST_URL = `${API_URL}/tractionist`;
export const BUILDING_URL = `${API_URL}/building`;
export const SLOT_URL = `${API_URL}/slot`;
export const STATUS_URL = `${API_URL}/status`;
export const TRAILER_URL = `${API_URL}/trailer`;
export const MOVING_URL = `${API_URL}/moving`;
export const MOVINGREQUEST_URL = `${MOVING_URL}/request`;
export const ACTION_URL = `${API_URL}/action`;
export const STATS_URL = `${API_URL}/stats`;

export const ICON_SIZE14 = 14;
export const ICON_SIZE16 = 16;
export const ICON_SIZE24 = 24;
export const ICON_SIZE40 = 40;

export const DATE_REGEX = new RegExp(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/)
export const TIME_REGEX = new RegExp(/^([0-1][0-9]|2[0-3])h[0-5][0-9]$/)
export const CONTAINER_REGEX = new RegExp(/([a-zA-Z]{3})([UJZujz])(\d{6})\/(\d)/);
export const TRAILER_REGEX = new RegExp(/^[A-Z]{2}[-][0-9]{3}[-][A-Z]{2}$/);
export const TEMPERATURE_REGEX = new RegExp(/^[+-]?[0-9]{1,2}(\.[0-9]{1,2})?$/);
export const FULL_REGEX = new RegExp(/(?:^|\W)Plein(?:$|\W)/);
export const EMPTY_REGEX = new RegExp(/(?:^|\W)Vide(?:$|\W)/);

export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.71)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    maxWidth: '90%',
    height: 'auto',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 20,
    borderColor: '#28A745',
    borderWidth: 2,
    alignItems: 'flex-start',
    backgroundColor: '#FFFFFF'
  }
}
export const customStylesTemperature = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.71)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    maxWidth: '90%',
    height: 'auto',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 20,
    borderColor: '#28A745',
    borderWidth: 2,
    alignItems: 'flex-start',
    backgroundColor: '#FFFFFF'
  }
}
export const customStylesCart = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.71)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '1200px',
    maxWidth: '90%',
    maxHeight: '90%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderRadius: 20,
    borderColor: '#28A745',
    borderWidth: 2,
    alignItems: 'flex-start',
    backgroundColor: '#FFFFFF'
  }
}

export const customStylesDatatable = {
  table: {
    style: {
      borderLeft: '0.1rem solid rgba(216, 216, 216, 0.8)',
      borderRight: '0.1rem solid rgba(216, 216, 216, 0.8)',
      borderBottom: '0.1rem solid rgba(216, 216, 216, 0.8)',
      backgroundColor: 'rgba(252, 252, 252, 0.8)',
    }
  },
  header: {
    style: {
      borderLeft: '0.1rem solid rgba(216, 216, 216, 0.8)',
      borderRight: '0.1rem solid rgba(216, 216, 216, 0.8)',
      borderTop: '0.1rem solid rgba(216, 216, 216, 0.8)',
      fontSize: '16px',
    }
  },
  headCells: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      padding: '0',
      backgroundColor: 'rgba(252, 252, 252, 0.8)',
    },
  },
  rows: {
    style: {
      '&:nth-child(odd)': {
        backgroundColor: 'rgba(244, 244, 244, 0.8)'
      },
      '&:last-child': {
        fontWeight: 'bold'
      },
      backgroundColor: 'rgba(252, 252, 252, 0.8)',
    }
  },
  cells: {
    style: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      padding: '0.7rem',
      marginLeft: '1rem',
      marginRight: '1rem',
    }
  },
  headRow: {
    style: {
      margin: 0, padding: 0
    }
  }
}

