import {
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, TEMPERATURE_REGEX, TRAILER_URL
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import useAuth from '../../../hooks/useAuth';
import { FcInspection } from 'react-icons/fc';
import { AiOutlineEdit } from 'react-icons/ai';
import CustomLabel from '../../label/CustomLabel';
import { useState } from 'react';
import CustomInput from '../../input/CustomInput';
import BaseModal from "../BaseModal";
import EditTheorethicalTemperature from "./EditTheorethicalTemperature";
import toast from "react-hot-toast";
import { render } from "@react-email/render";
import { isDatePast } from "../../../utils/functions";
import TemperatureAlertsEmail from "../../mail/TemperatureAlertsEmail";
import CancelOnGoingActionModal from "../ParkState/CancelOnGoingActionModal";
import TemperatureConfirmationModal from "./TemperatureConfirmationModal";
import ModalModifyButton from "../../button/ModalButton/ModalModifyButton";

const EditTemperature = ({ data, modalVisible, setModalVisible, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const [selectedTemperature, setSelectedTemperature] = useState(data.temperature ? data.temperature : '');
  const [editTheoreticalTemperature, setEditTheoreticalTemperature] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const sendModifications = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Modification en cours...');

    if (selectedTemperature === '' || !selectedTemperature) {
      toast.error('Veuillez sélectionner une température', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    if (!TEMPERATURE_REGEX.test(selectedTemperature)) {
      toast.error('Veuillez entrer une température valide', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    const theoreticalTemperature = parseFloat(data.temperature_theorique);
    const selectedTemp = parseFloat(selectedTemperature);
    const lowerLimit = theoreticalTemperature * 0.9;
    const upperLimit = theoreticalTemperature * 1.1;
    console.log(selectedTemp, lowerLimit, upperLimit)
    let emailHtml = '';
    const isPast4hours = isDatePast(data.date_arrivee);

    if(selectedTemp < 0){
      if (isPast4hours && (selectedTemp > lowerLimit || selectedTemp < upperLimit)) {
        emailHtml = render(<TemperatureAlertsEmail container={data.label} temperature={selectedTemp}
                                                   temperature_theorique={theoreticalTemperature}/>);
      }
    }else {
      if (isPast4hours && (selectedTemp < lowerLimit || selectedTemp > upperLimit)) {
        emailHtml = render(<TemperatureAlertsEmail container={data.label} temperature={selectedTemp}
                                                   temperature_theorique={theoreticalTemperature}/>);
      }
    }


    const trailer = {
      id_remorque: data.id,
      id_utilisateur: auth.id,
      temperature: selectedTemp,
      id_statut: data.id_statut,
      code_batiment: data.code_batiment,
      html: emailHtml
    };

    await axiosApi.post(`${TRAILER_URL}/update/temperature`, { trailer })
      .then((result) => {
        if (result.data.success) {
          console.log(result.data);
          toast.success('La température a bien été modifiée.', { id: toastLoading });
          if(result.data.message) toast.success(result.data.message);
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de la modification de température', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const handleSubmit = () => {
    const isPast4hours = isDatePast(data.date_arrivee);
    const theoreticalTemperature = parseFloat(data.temperature_theorique);
    const selectedTemp = parseFloat(selectedTemperature);
    const lowerLimit = theoreticalTemperature * 0.9;
    const upperLimit = theoreticalTemperature * 1.1;

    if (selectedTemp > 0) {
      if (isPast4hours && (selectedTemp < lowerLimit || selectedTemp > upperLimit)) {
        setConfirmationModal(true);
      } else {
        sendModifications();
      }
    } else if (isPast4hours && (selectedTemp > lowerLimit || selectedTemp < upperLimit)) {
      setConfirmationModal(true);
    } else {
      sendModifications();
    }
  };

  const defrostTrailer = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Modification en cours...');

    await axiosApi.post(`${TRAILER_URL}/defrost`,
      {
        id: data.id
      })
      .then((result) => {
        if (result.data.success) {
          toast.success(CONTAINER_REGEX.test(data.num_remorque) ? `Le conteneur ${data.num_remorque} est en cours de dégivrage` : `La remorque ${data.num_remorque} est en cours de dégivrage`, { id: toastLoading })
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de la mise en dégivrage', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
    setIsLoading(false);
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible && (
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Modifier température</label>
            <div onClick={() => setModalVisible(!modalVisible)}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque) ? (
              <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>
            ) : (
              <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            )}
          </div>

          <div className={styles.WrapperRow}>
            <label className={styles.ModalText}>Température théorique : {data.temperature_theorique}°C</label>
            {(auth.role === 1 || auth.role === 3 || auth.role === 4) && (
              <div className={styles.IconRightWrapper}>
                <AiOutlineEdit
                  size={ICON_SIZE24}
                  className={styles.Icon}
                  onClick={() => setEditTheoreticalTemperature(!editTheoreticalTemperature)}
                />
              </div>
            )}
          </div>

          <div className={styles.Wrapper}>
            <CustomLabel label={'Température relevée(°C)'}/>
            <div className={styles.TextWrapper}>
              <CustomInput
                value={selectedTemperature}
                type={'numeric'}
                setValue={setSelectedTemperature}
                placeholder={'ex: -20'}
                maxLength={5}
              />
            </div>
          </div>

          <div className={styles.ButtonWrapper}>
            <ModalCancelButton label={'Retour'} onClick={() => setModalVisible(false)} isLoading={isLoading}/>
            <ModalModifyButton label={'Dégivrage'} onClick={defrostTrailer} isLoading={isLoading}/>
            <ModalSuccessButton label={'Valider la modification'} onClick={handleSubmit} isLoading={isLoading}/>
          </div>

          <EditTheorethicalTemperature
            data={data}
            modalVisible={editTheoreticalTemperature}
            setModalVisible={setEditTheoreticalTemperature}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setRefresh={setRefresh}
          />
          <TemperatureConfirmationModal
            data={data}
            mainModal={modalVisible}
            setMainModal={setModalVisible}
            modalVisible={confirmationModal}
            setModalVisible={setConfirmationModal}
            temperature={selectedTemperature}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setRefresh={setRefresh}
          />
        </>
      )}
    </BaseModal>
  );
}

export default EditTemperature;