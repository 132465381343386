import {
  BUILDING_URL, CONTAINER_REGEX,
  EMPTY_REGEX, FULL_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL, SLOT_URL, STATUS_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import BasicCheckbox from '../../checkbox/BasicCheckbox';
import { useEffect, useState } from 'react';
import { getSelected } from '../../../utils/functions';
import { FcViewDetails } from 'react-icons/fc';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const ModifyModal = ({
                       data,
                       mainModal, setMainModal,
                       modalVisible, setModalVisible,
                       isLoading, setIsLoading,
                       setRefresh
                     }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isPlugged, setIsPlugged] = useState(false);
  const [buildings, setBuildings] = useState('');
  const [slots, setSlots] = useState('');
  const [status, setStatus] = useState('');

  const getBuilding = async () => {
    await axiosApi.get(`${BUILDING_URL}/all`)
      .then((result) => {
        setBuildings(result.data.list);
        setSelectedBuilding(getSelected(result.data.list, data.code_batiment_demande)[0]);
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
      });
  }

  const getStatus = async () => {
    await axiosApi.get(`${STATUS_URL}/trailer`)
      .then((result) => {
        setStatus(result.data.list);
        setSelectedStatus(getSelected(result.data.list, data.id_statut_deplacement)[0]);
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
      });
  }

  const getSlots = async () => {
    const code = selectedBuilding.id;
    await axiosApi.post(`${SLOT_URL}/code`,
      {
        code
      })
      .then((result) => {
        setSlots(result.data.list);
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
      });
  }

  const initModifyModal = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement...');
    await getBuilding()
      .then(async () => {
          await getStatus()
            .then(() => {
                let checked = false;
                if (data.est_branche) {
                  checked = true;
                }
                setIsPlugged(checked);
                setIsLoading(false);
                toast.remove(toastLoading);
              }
            )
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      )
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const modifyMoveRequest = async (id) => {
    const toastLoading = toast.loading('Modification en cours...');
    setIsLoading(true);
    if (selectedBuilding.id === '') {
      toast.error('Veuillez sélectionner un bâtiment', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    if (selectedSlot.id === '' || selectedSlot.id === undefined || selectedSlot.id === null || Array.isArray(selectedSlot)) {
      toast.error('Veuillez sélectionner un emplacement', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    if (selectedStatus.id === '0') {
      toast.error('Veuillez sélectionner un statut', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
      {
        id,
        id_statut_deplacement: selectedStatus.id,
        code_batiment: selectedBuilding.id,
        emplacement: selectedSlot.id,
        est_branche: isPlugged,
        id_utilisateur: auth.id
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('Modification effectuée', { id: toastLoading });
          setIsLoading(false);
          setModalVisible(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (selectedBuilding !== '') {
      getSlots();
    }
  }, [selectedBuilding])

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);
      setSelectedSlot([]);
      initModifyModal();
    }
  }, [modalVisible])

  useEffect(() => {
    if (slots !== '' && slots.length !== 0) {
      if (selectedSlot.length !== 0) {
        setSelectedSlot(slots[0])
      } else {
        setSelectedSlot(getSelected(slots, data.emplacement_demande)[0]);
      }
    }
  }, [slots]);

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(false);
      }
    }
  }, [selectedStatus]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Modification mission</label>
              <div onClick={() => {
                setSelectedSlot([]);
                setModalVisible(!modalVisible);
                setMainModal(!mainModal);
              }}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.Wrapper}>
              <label className={styles.ModalTextBold}> {CONTAINER_REGEX.test(data.num_remorque) ? `Conteneur : ${data.num_remorque}` : `Remorque : ${data.num_remorque}`}</label>
            </div>
            <div className={styles.SplitSelect}>
              <div className={styles.position}>
                <div className={styles.Wrapper}>
                  <CustomLabel
                    label={'Bâtiment'}
                  />
                  <CustomSearchableSelect
                    data={buildings}
                    placeholder={'Sélectionner un bâtiment'}
                    setValue={setSelectedBuilding}
                    defaultValue={selectedBuilding}
                  />
                </div>
              </div>
              <div className={styles.position}>
                <div className={styles.Wrapper}>
                  <CustomLabel
                    label={'Emplacement/Quai'}
                  />
                  <CustomSearchableSelect
                    data={slots}
                    placeholder={'Sélectionner un emplacement/quai'}
                    setValue={setSelectedSlot}
                    defaultValue={selectedSlot}
                  />
                </div>
              </div>
            </div>
            <div className={styles.WrapperColumn}>
              <CustomLabel
                label={'Choisir un statut'}
              />
              <CustomSearchableSelect
                data={status}
                placeholder={'Sélectionner un statut'}
                setValue={setSelectedStatus}
                defaultValue={selectedStatus}
              />
            </div>
            <div className={styles.Wrapper}>
              <BasicCheckbox
                label={'branché'}
                value={isPlugged}
                setValue={setIsPlugged}
              />
            </div>
            <BasicModalButton
              label={'Modifier la mission'}
              onClick={() => {
                modifyMoveRequest(data.id)
              }}
              isLoading={isLoading}
            />
          </>
      }
    </BaseModal>
  )
}

export default ModifyModal;