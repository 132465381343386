import { useEffect, useState } from 'react';
import useAxiosApi from '../../hooks/useAxiosApi';
import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import { ACTION_URL, BUILDING_URL, MOVINGREQUEST_URL } from '../../constants';
import CustomLabel from '../../components/label/CustomLabel';
import CustomOnGoingText from '../../components/text/CustomOnGoingText';
import CustomSplitText from '../../components/text/CustomSplitText';
import styles from './Mission.module.scss';
import CustomActionText from '../../components/text/CustomActionText';
import CustomOnGoingActionText from '../../components/text/CustomOnGoingActionText';
import CustomSearchableSelect from '../../components/select/CustomSearchableSelect/CustomSearchableSelect';
import toast from "react-hot-toast";

const Mission = () => {
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [buildings, setBuildings] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [pendingMissions, setPendingMissions] = useState(null);
  const [onGoingMissions, setOnGoingMissions] = useState(null);
  const [actions, setActions] = useState(null);
  const [onGoingActions, setOnGoingActions] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const filter = [
    {
      value: 0,
      label: 'Départ',
    },
    {
      value: 1,
      label: 'Destination',
    },
  ]

  const fetchBuilding = async () => {
    const toastLoading = toast.loading('Chargement en cours...');
    setIsLoading(true);

    await axiosApi.get(`${BUILDING_URL}/all`)
      .then((result) => {
        if (result) {
          setBuildings(result.data.list);
          setIsLoading(false);
          toast.remove(toastLoading);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const fetchMissions = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    let paramBuilding = null;
    let paramFilter = null;

    if (selectedBuilding) {
      paramBuilding = selectedBuilding.value;
    }
    if (selectedFilter) {
      paramFilter = selectedFilter.value;
    }

    await axiosApi.post(`${ACTION_URL}/get`, {
      ordre: 0,
      code_batiment: paramBuilding
    })
      .then(async (result) => {
        setActions(result.data.list);
        await axiosApi.post(`${ACTION_URL}/get`, {
          ordre: 1,
          code_batiment: paramBuilding
        })
          .then(async (result) => {
            setOnGoingActions(result.data.list);
            await axiosApi.post(`${MOVINGREQUEST_URL}/get`, {
              ordre: 1,
              code_batiment: paramBuilding,
              filtre: paramFilter
            })
              .then(async (result) => {
                setOnGoingMissions(result.data.list);
                await axiosApi.post(`${MOVINGREQUEST_URL}/get`, {
                  ordre: 0,
                  code_batiment: paramBuilding,
                  filtre: paramFilter
                })
                  .then((result) => {
                    setPendingMissions(result.data.list);
                    toast.remove(toastLoading);
                    setIsLoading(false);
                  }).catch((err) => {
                    toast.error('Une erreur est survenue', { id: toastLoading });
                    setIsLoading(false);
                  });
              }).catch((err) => {
                toast.error('Une erreur est survenue', { id: toastLoading });
                setIsLoading(false);
              });
          }).catch((err) => {
            toast.error('Une erreur est survenue', { id: toastLoading });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!buildings) {
      fetchBuilding();
    }
  }, [])

  useEffect(() => {
    if (refresh) {
      fetchMissions()
        .then(() => {
          setRefresh(false);
        });
    }
  }, [refresh]);

  useEffect(() => {
    if (buildings && !selectedBuilding) {
      setSelectedFilter(null);
      fetchMissions();
    } else if (buildings) {
      fetchMissions();
    }
  }, [buildings, selectedBuilding, selectedFilter]);


  return (
    <DefaultLayout title={'Missions'}>
      <div className={styles.Form}>
        <div className={styles.Wrapper}>
          <div className={styles.Wrapper}>
            <div className={styles.SplitSelect}>
              <div className={styles.position}>
                <CustomLabel
                  label={'Bâtiment'}
                />
                <CustomSearchableSelect
                  data={buildings}
                  defaultValue={selectedBuilding}
                  placeholder={'Sélectionner un bâtiment'}
                  setValue={setSelectedBuilding}
                  isClearable
                />
              </div>
              {
                selectedBuilding &&
                <div className={styles.position}>
                  <CustomLabel
                    label={'Filtre'}
                  />
                  <CustomSearchableSelect
                    data={filter}
                    defaultValue={selectedFilter}
                    placeholder={'Sélectionner un filtre'}
                    setValue={setSelectedFilter}
                    isClearable
                  />
                </div>
              }
            </div>
          </div>
          <div className={styles.MissionWrapper}>
            <div className={styles.WrapperSplit}>
              {
                onGoingActions !== null && onGoingActions.length > 0 &&
                <>
                  <label className={styles.Label}>Actions en cours</label>
                  {
                    onGoingActions.map((elem) => {
                      return (
                        <CustomOnGoingActionText
                          data={elem}
                          key={elem.id}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setRefresh={setRefresh}
                        />
                      )
                    })}
                </>
              }
              <label className={styles.Label}>Missions en cours</label>
              {
                onGoingMissions !== null && onGoingMissions.length > 0 ?
                  onGoingMissions.map((elem) => {
                    return (
                      <CustomOnGoingText
                        data={elem}
                        key={elem.id}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setRefresh={setRefresh}
                      />
                    )
                  })
                  :
                  <CustomLabel label={'Aucune mission en cours'}/>
              }
            </div>
            <div className={styles.WrapperSplit}>
              {
                actions !== null && actions.length > 0 &&
                <>
                  <label className={styles.Label}>Actions à faire</label>
                  {
                    actions.map((elem) => {
                      return (
                        <CustomActionText
                          data={elem}
                          key={elem.id}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          setRefresh={setRefresh}
                        />
                      )
                    })}
                </>
              }
              <label className={styles.Label}>Missions à faire</label>
              {
                pendingMissions !== null && pendingMissions.length > 0 ?
                  pendingMissions.map((elem) => {
                    return (
                      <CustomSplitText
                        data={elem}
                        key={elem.id}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setRefresh={setRefresh}
                      />
                    )
                  })
                  :
                  <CustomLabel label={'Aucune mission à faire'}/>
              }
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default Mission;