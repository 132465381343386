import styles from './Input.module.scss';

const TextAreaInput = ({ value, setValue, placeholder = '', maxLength = 50 }) => {
  return (
    <div className={styles.CustomInput}>
      <textarea
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        className={styles.InputArea}
        rows={6}
      />
    </div>
  )
}

export default TextAreaInput;