import {PulseLoader} from 'react-spinners';

const PulseSpinner = ({ size = 7, isLoading = false }) => {
  return (
    <PulseLoader
      size={size}
      color={'#28A745'}
      loading={isLoading}
    />
  )
}

export default PulseSpinner;