import {
  CONTAINER_REGEX, DATE_REGEX, EMPTY_REGEX, FULL_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, STATUS_URL, TEMPERATURE_REGEX, TIME_REGEX, TRACTIONIST_URL, TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import { useEffect, useState } from 'react';
import { formatDate, getSelected } from '../../../utils/functions';
import { FcViewDetails } from 'react-icons/fc';
import CustomInput from "../../input/CustomInput";
import BasicCheckbox from "../../checkbox/BasicCheckbox";
import BaseModal from "../BaseModal";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

const EditModal = ({
                     data,
                     modalVisible, setModalVisible,
                     isLoading, setIsLoading,
                     setRefresh
                   }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const [tractionnist, setTractionnist] = useState('');
  const [selectedTractionnist, setSelectedTractionnist] = useState('');
  const [status, setStatus] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isPlugged, setIsPlugged] = useState(data?.est_branche ? data.est_branche : false);
  const [temperature, setTemperature] = useState(0);
  const [date, setDate] = useState();
  const [isDry, setIsDry] = useState(false);

  const getTractionnist = async () => {
    await axiosApi.get(`${TRACTIONIST_URL}/all`)
      .then((result) => {
        setTractionnist(result.data.list);
        setSelectedTractionnist(getSelected(result.data.list, data.id_tractionnaire)[0]);
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
        setIsLoading(false)
      });
  }

  const initModifyModal = async () => {
    setIsLoading(true);
    console.log(data);
    const toastLoading = toast.loading('Chargement en cours...');
    await getTractionnist()
      .then(async () => {
          setTemperature(data.temperature_theorique);
          if (data.date_arrivee) {
            let tempDate = new Date(data.date_arrivee);
            tempDate.setHours(tempDate.getHours() - 1);
            setDate(tempDate)
          }

          await axiosApi.get(`${STATUS_URL}/trailer`)
            .then((result) => {
              setStatus(result.data.list);
              setSelectedStatus(getSelected(result.data.list, data.id_statut)[0]);
              if (data.id_type_remorque === 3) {
                setIsDry(true);
              } else {
                setIsDry(false)
              }
              toast.remove(toastLoading);
              setIsLoading(false);
            })
            .catch((err) => {
              toast.remove(toastLoading);
              setIsLoading(false);
            })
        }
      )
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const updateTrailer = async () => {
    setIsLoading(true)
    const toastLoading = toast.loading('Chargement en cours...');
    if (data.date_arrivee) {
      let fDate = '';
      let fTime = '';

      if (date !== null) {
        fDate = ('0' + date.getUTCDate()).slice(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).slice(-2) + '/' + date.getFullYear();
        fTime = ('0' + date.getHours()).slice(-2) + 'h' + ('0' + date.getMinutes()).slice(-2);
      }
      if (fDate === '' || fTime === '') {
        toast.error('Veuillez entrer une date ou une heure valable', { id: toastLoading });
        setIsLoading(false);
        return;
      }
      if (!DATE_REGEX.test(fDate)) {
        toast.error('Veuillez entrer une date valable', { id: toastLoading });
        setIsLoading(false);
        return;
      }
      if (!TIME_REGEX.test(fTime)) {
        toast.error('Veuillez entrer une heure valable', { id: toastLoading });
        setIsLoading(false);
        return;
      }
      if (temperature === '') {
        toast.error('Veuillez sélectionner une température', { id: toastLoading });
        setIsLoading(false);
        return;
      }

      if (!TEMPERATURE_REGEX.test(temperature)) {
        toast.error('Veuillez entrer une température valide', { id: toastLoading });
        setIsLoading(false)
        return;
      }

      if (!selectedStatus) {
        toast.error('Veuillez sélectionner un statut valide', { id: toastLoading });
        setIsLoading(false)
        return;
      }

      await axiosApi.post(`${TRAILER_URL}/update`,
        {
          id: data.id,
          id_statut: selectedStatus.value,
          id_tractionnaire: selectedTractionnist.value,
          temperature_theorique: temperature,
          id_type_remorque: data.id_type_remorque,
          est_sec: isDry,
          date_arrivee: formatDate(fDate, fTime),
          id_user: auth.id,
          est_branche: isPlugged ? 1 : 0
        })
        .then((result) => {
          if (result.data.success) {
            toast.success('Les données ont bien été mise à jour', { id: toastLoading });
            setIsLoading(false)
            setRefresh(true);
          } else {
            toast.error('Une erreur est survenue lors de la mise à jour', { id: toastLoading });
            setIsLoading(false)
          }
        })
        .catch((err) => {
          toast.error('Une erreur est survenue', { id: toastLoading });
          setIsLoading(false)
        });
    } else {
      if (temperature === '') {
        toast.error('Veuillez sélectionner une température', { id: toastLoading });
        setIsLoading(false)
        return;
      }

      if (!TEMPERATURE_REGEX.test(temperature)) {
        toast.error('Veuillez entrer une température valide', { id: toastLoading });
        setIsLoading(false)
        return;
      }

      if (!selectedStatus) {
        toast.error('Veuillez sélectionner un statut valide', { id: toastLoading });
        setIsLoading(false)
        return;
      }

      await axiosApi.post(`${TRAILER_URL}/update`,
        {
          id: data.id,
          id_statut: selectedStatus.value,
          id_tractionnaire: selectedTractionnist.value,
          temperature_theorique: temperature,
          id_type_remorque: data.id_type_remorque,
          est_sec: isDry,
          id_user: auth.id
        })
        .then((result) => {
          if (result.data.success) {
            toast.success('Les données ont bien été mise à jour', { id: toastLoading });
            setIsLoading(false)
            setRefresh(true);
          } else {
            toast.error('Une erreur est survenue lors de la mise à jour', { id: toastLoading });
            setIsLoading(false)
          }
        })
        .catch((err) => {
          toast.error('Une erreur est survenue', { id: toastLoading });
          setIsLoading(false)
        });
    }
  }

  useEffect(() => {
    if (modalVisible) {
      setIsLoading(true);

      initModifyModal();
    }
  }, [modalVisible])

  useEffect(() => {
    if (data) {
      if (isDry) {
        setTemperature(0);
      } else {
        setTemperature(data.temperature_theorique);
      }
    }
  }, [isDry])

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(selectedStatus === getSelected(status, data.id_statut)[0] ? data.est_branche : false);
      }
    }
  }, [selectedStatus]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label
              className={styles.HeaderText}>{CONTAINER_REGEX.test(data.num_remorque) ? 'Modifier conteneur' : 'Modifier remorque'}</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          {
            data.date_arrivee &&
            <div className={styles.SplitSelect}>
              <div className={styles.position}>
                <CustomLabel
                  label={'Date d\'arrivée'}
                />
                <DatePicker
                  selected={date}
                  onChange={(d) => setDate(d)}
                  timeInputLabel='Heure:'
                  dateFormat='dd/MM/yyyy HH:mm'
                  showTimeInput
                  locale='fr'
                  customInput={<input className={styles.DatePicker} type='text' value={date}/>}
                />
              </div>
            </div>
          }
          <div className={styles.Wrapper}>
            <CustomLabel
              label={'Choisir un statut'}
            />
            <CustomSearchableSelect
              data={status}
              placeholder={'Sélectionner un statut'}
              setValue={setSelectedStatus}
              defaultValue={selectedStatus}
            />
          </div>
          <div className={styles.Wrapper}>
            <BasicCheckbox label={'branché'} value={isPlugged} setValue={setIsPlugged}
                           disabled={FULL_REGEX.test(selectedStatus.libelle) || EMPTY_REGEX.test(selectedStatus.libelle)}/>
          </div>
          <div className={styles.Wrapper}>
            <CustomLabel
              label={'Tractionnaire'}
            />
            <CustomSearchableSelect
              data={tractionnist}
              placeholder={'Sélectionner un tractionnaire'}
              setValue={setSelectedTractionnist}
              defaultValue={selectedTractionnist}
            />
          </div>
          {!isDry &&
            <div className={styles.SplitSelect}>
              <div className={styles.position}>
                <CustomLabel
                  label={'Température théorique(°C)'}
                />
                <div className={styles.WrapperRow}>
                  <CustomInput
                    value={temperature}
                    setValue={setTemperature}
                    placeholder={'ex: -20'}
                    maxLength={5}
                  />
                </div>
              </div>
            </div>
          }
          {
            data.id_type_remorque !== 2 &&
            <div className={styles.Wrapper}>
              <BasicCheckbox
                label={'Conteneur sec'}
                value={isDry}
                setValue={setIsDry}
                disabled={false}
              />
            </div>
          }

          <BasicModalButton
            label={CONTAINER_REGEX.test(data.num_remorque) ? 'Modifier conteneur' : 'Modifier remorque'}
            onClick={() => {
              updateTrailer()
            }}
            isLoading={isLoading}
          />
        </>
      }
    </BaseModal>
  )
}

export default EditModal;