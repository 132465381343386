import styles from '../Forms.module.scss';
import { useEffect, useRef, useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import { customStylesDatatable, TRAILER_URL } from '../../../constants';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import toast from "react-hot-toast";

const FormParkTractionnist = () => {
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    toast.remove();
    const toastLoading = toast.loading('Chargement en cours...');
    await axiosApi.get(`${TRAILER_URL}/stats/tractionnist`)
      .then(async (result) => {
        setStats(result.data.list)
        setIsLoading(false);
        toast.remove(toastLoading);
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }
  useEffect(() => {
      fetchData();
  }, []);

  const columns = [
    {
      name: 'Tractionnaire',
      selector: row => row.tractionnaire,
      sortable: true
    },
    {
      name: 'Plein à dépoter',
      selector: row => row.depoter,
      center: true
    },
    {
      name: 'Vide à empoter',
      selector: row => row.empoter,
      center: true
    },
    {
      name: 'Plein attente reprise',
      selector: row => row.pleinreprise,
      center: true
    },
    {
      name: 'Plein attente consigne',
      selector: row => row.pleinconsigne,
      center: true
    },
    {
      name: 'Plein à surveiller',
      selector: row => row.pleinsurveiller,
      center: true
    },
    {
      name: 'Vide attente reprise',
      selector: row => row.videreprise,
      center: true
    },
    {
      name: 'Vide à retirer',
      selector: row => row.videretirer,
      center: true
    },
    {
      name: 'Total',
      selector: row => row.total,
      center: true
    },
  ];

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Tractionnaire', key: 'tractionnaire' },
    { label: 'Plein à dépoter', key: 'depoter' },
    { label: 'Vide à empoter', key: 'empoter' },
    { label: 'Plein en attente reprise', key: 'pleinreprise' },
    { label: 'Plein en attente de consigne', key: 'pleinconsigne' },
    { label: 'Plein à surveiller', key: 'pleinsurveiller' },
    { label: 'Vide en attente reprise', key: 'videreprise' },
    { label: 'Vide à retirer', key: 'videretirer' },
    { label: 'Total', key: 'total' },
  ];


  return (
    <>
      <div className={styles.FormFullWidth}>
        {!isLoading &&
          <DataTable
            title={'Tableau de suivi des tractionnaires'}
            columns={columns}
            data={stats}
            customStyles={customStylesDatatable}
            dense
            highlightOnHover
            actions={
              <CSVLink
                filename={'suivi_tractionnaire'}
                data={stats}
                headers={headers}
                separator={';'}
                target={'_blank'}
                className={styles.Export}>Exporter
              </CSVLink>
            }
            responsive
          />
        }
      </div>
    </>
  )
}

export default FormParkTractionnist;