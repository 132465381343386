import DefaultLayout from '../components/templates/DefaultLayout/DefaultLayout';
import FormSearch from '../components/form/FormSearch';

const TrailerSearch = () => {
  return (
    <DefaultLayout title={'Recherche conteneur'}>
      <FormSearch/>
    </DefaultLayout>
  )
}

export default TrailerSearch;