import {
  BUILDING_URL, CONTAINER_REGEX,
  EMPTY_REGEX, FULL_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL, SLOT_URL, STATUS_URL, TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import BasicCheckbox from '../../checkbox/BasicCheckbox';
import { useEffect, useState } from 'react';
import { getSelected } from '../../../utils/functions';
import { FcViewDetails } from 'react-icons/fc';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { render } from "@react-email/render";
import ComebackContainerEmail from "../../mail/ComebackContainerEmail";

const MovingModal = ({
                       data,
                       modalVisible, setModalVisible,
                       isLoading, setIsLoading,
                       setRefresh
                     }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isPlugged, setIsPlugged] = useState(false);
  const [buildings, setBuildings] = useState('');
  const [slots, setSlots] = useState([]);
  const [status, setStatus] = useState('')

  const getBuilding = async () => {
    await axiosApi.get(`${BUILDING_URL}/all`)
      .then((result) => {
        setBuildings(result.data.list);
        if (data.code_batiment !== 'HP') {
          setSelectedBuilding(getSelected(result.data.list, data.code_batiment)[0]);
        }
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
        setIsLoading(false);
      });
  }

  const getStatus = async () => {
    await axiosApi.get(`${STATUS_URL}/trailer`)
      .then((result) => {
        setStatus(result.data.list);
        if (data.code_batiment !== 'HP') {
          setSelectedStatus(getSelected(result.data.list, data.id_statut)[0]);
        }
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
        setIsLoading(false);
      });
  }

  const getSlots = async () => {
    const code = selectedBuilding.id;
    await axiosApi.post(`${SLOT_URL}/code`,
      {
        code
      })
      .then((result) => {
        setSlots(result.data.list);
      })
      .catch((err) => {
        toast.remove();
        toast.error('Une erreur est survenue');
        setIsLoading(false);
      });
  }

  const initModifyModal = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement...');
    await getBuilding()
      .then(async () => {
          await getStatus()
            .then(() => {
                let checked = false;
                if (data.est_branche) {
                  checked = true;
                }
                setIsPlugged(checked);
                toast.remove(toastLoading);
                setIsLoading(false);
              }
            )
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      )
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const modifyMoveRequest = async () => {
    const toastLoading = toast.loading('Modification en cours...');
    setIsLoading(true);
    if (selectedBuilding === '' || selectedBuilding === undefined) {
      toast.error('Veuillez sélectionner un bâtiment', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    if (selectedSlot.id === '' || selectedSlot.id === undefined || selectedSlot.id === null || Array.isArray(selectedSlot)) {
      toast.error('Veuillez sélectionner un emplacement', { id: toastLoading });
      setIsLoading(false);
      return;
    }
    if (selectedStatus.id === '0' || selectedStatus === '' || selectedStatus === undefined) {
      toast.error('Veuillez sélectionner un statut', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    let emailHtml = '';
    if(CONTAINER_REGEX.test(data.num_remorque) && data.code_batiment === "HP") {
      emailHtml = render(<ComebackContainerEmail container={data.num_remorque} status={selectedStatus.label}
                                                temperature={data.temperature_theorique}
                                                tractionnist={data.tractionnaire} isDry={data.id_type_remorque === 3}
                                                code_batiment={selectedBuilding.id}
                                                emplacement={selectedSlot.id}/>);
    }

    if (selectedSlot.id !== 0) {
      await axiosApi.post(`${TRAILER_URL}/check/position`,
        {
          code_batiment: selectedBuilding.id,
          emplacement: selectedSlot.id
        })
        .then((result) => {
          if (result.data.success) {
            toast.error('Cet emplacement est déjà occupé', { id: toastLoading });
            setIsLoading(false);
          } else {
            axiosApi.post(`${MOVINGREQUEST_URL}/check`,
              {
                id: data.id
              })
              .then((result) => {
                if (result.data.success) {
                  toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Vous avez déjà une demande de déplacement pour ce conteneur.' : 'Vous avez déjà une demande de déplacement pour cette remorque.');
                  setIsLoading(false);
                } else {
                  axiosApi.post(`${TRAILER_URL}/update/position`,
                    {
                      id: data.id,
                      code_batiment: selectedBuilding.id,
                      emplacement: selectedSlot.id,
                      id_statut: selectedStatus.id,
                      est_branche: isPlugged,
                      id_user: auth.id,
                      html: emailHtml
                    })
                    .then((result) => {
                      if (result.data.success) {
                        toast.success('Déplacement effectué.', { id: toastLoading });
                        setModalVisible(!modalVisible);
                        setRefresh(true);
                        setIsLoading(false);
                      } else {
                        toast.error('Une erreur est survenue.', { id: toastLoading });
                        setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      toast.error('Une erreur est survenue.', { id: toastLoading });
                      setIsLoading(false);
                    });
                }
              })
              .catch((err) => {
                toast.error('Une erreur est survenue.', { id: toastLoading });
                setIsLoading(false);
              });
          }
        })
        .catch((err) => {
          toast.error('Une erreur est survenue.', { id: toastLoading });
          setIsLoading(false);
        });
    } else {
      axiosApi.post(`${MOVINGREQUEST_URL}/check`,
        {
          id: data.id
        })
        .then((result) => {
          if (result.data.success) {
            toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Vous avez déjà une demande de déplacement pour ce conteneur.' : 'Vous avez déjà une demande de déplacement pour cette remorque.', { id: toastLoading });
            setIsLoading(false);
          } else {
            axiosApi.post(`${TRAILER_URL}/update/position`,
              {
                id: data.id,
                code_batiment: selectedBuilding.id,
                emplacement: selectedSlot.id,
                id_statut: selectedStatus.id,
                est_branche: isPlugged,
                id_user: auth.id,
                html: emailHtml
              })
              .then((result) => {
                if (result.data.success) {
                  toast.success('Déplacement effectué.', { id: toastLoading });
                  setIsLoading(false);
                  setRefresh(true);
                } else {
                  toast.error('Une erreur est survenue.', { id: toastLoading });
                  setIsLoading(false);
                }
              })
              .catch((err) => {
                toast.error('Une erreur est survenue.', { id: toastLoading });
                setIsLoading(false);
              });
          }
        })
        .catch((err) => {
          toast.error('Une erreur est survenue.', { id: toastLoading });
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (selectedBuilding !== '') {
      getSlots();
    }
  }, [selectedBuilding])

  useEffect(() => {
    if (modalVisible) {
      setSelectedSlot([]);
      initModifyModal();
    }
  }, [modalVisible])

  useEffect(() => {
    if (slots !== '' && slots.length !== 0) {
      if (selectedSlot.length !== 0) {
        setSelectedSlot(slots[0])
      } else {
        setSelectedSlot(getSelected(slots, data.emplacement)[0]);
      }
    }
  }, [slots]);

  useEffect(() => {
    if (selectedStatus !== '') {
      if (FULL_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(true);
      } else if (EMPTY_REGEX.test(selectedStatus.libelle)) {
        setIsPlugged(false);
      } else {
        setIsPlugged(false);
      }
    }
  }, [selectedStatus]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Déplacement</label>
            <div onClick={() => {
              setSelectedSlot([]);
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.SplitSelect}>
            <div className={styles.position}>
              <CustomLabel
                label={'Bâtiment'}
              />
              <div className={styles.Wrapper}>
                <CustomSearchableSelect
                  data={buildings}
                  placeholder={'Sélectionner un bâtiment'}
                  setValue={setSelectedBuilding}
                  defaultValue={selectedBuilding}
                />
              </div>
            </div>
            <div className={styles.position}>
              <CustomLabel
                label={'Emplacement/Quai'}
              />
              <div className={styles.Wrapper}>
                <CustomSearchableSelect
                  data={slots}
                  placeholder={'Sélectionner un emplacement/quai'}
                  setValue={setSelectedSlot}
                  defaultValue={selectedSlot}
                />
              </div>
            </div>
          </div>
          <div className={styles.WrapperColumn}>
            <CustomLabel
              label={'Choisir un statut'}
            />
            <CustomSearchableSelect
              data={status}
              placeholder={'Sélectionner un statut'}
              setValue={setSelectedStatus}
              defaultValue={selectedStatus}
            />
          </div>
          <div className={styles.Wrapper}>
            <BasicCheckbox label={'branché'} value={isPlugged} setValue={setIsPlugged} disabled={FULL_REGEX.test(selectedStatus.libelle) || EMPTY_REGEX.test(selectedStatus.libelle)}/>
          </div>
          <BasicModalButton
            label={'Confirmer le déplacement'}
            onClick={() => {
              modifyMoveRequest()
            }}
            isLoading={isLoading}
          />
        </>
      }
    </BaseModal>
  )
}

export default MovingModal;