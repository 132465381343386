import styles from './ButtonTemperature.module.scss';
import PulseSpinner from '../../spinner/PulseSpinner';

const ButtonTemperature = ({ label, onClick, isLoading = false, disabled }) => {
  return (
    <div className={styles.BasicButtonContainer}>
      <button
        className={disabled ? styles.DisabledButton : isLoading ? styles.DisabledButton : styles.BasicButton}
        onClick={onClick}
        disabled={disabled ? disabled : isLoading}>
        {isLoading
          ? <PulseSpinner isLoading={isLoading}/>
          : label
        }
      </button>
    </div>
  )
}

export default ButtonTemperature;