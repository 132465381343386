import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styles from './Breadcrumbs.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { getPathLabel } from '../../utils/functions';

const MUIBreadcrumbs = () => {

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    location.pathname !== '/' ?
      <div className={styles.Breadcrumbs}>
        <Breadcrumbs aria-label='breadcrumb'>
          {pathnames.length > 0 ? (
            <Link className={styles.Link} to={'/'}>Accueil</Link>
          ) : (
            <Typography> Accueil </Typography>
          )}
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Typography key={name}>{getPathLabel(name)}</Typography>
            ) : (
              <Link className={styles.Link} key={name} to={routeTo}>
                {getPathLabel(name)}
              </Link>
            );
          })}
        </Breadcrumbs>
      </div>
      : null
  );
}

export default MUIBreadcrumbs;
