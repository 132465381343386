import Header from '../../header/Header';
import './ParkLayout.module.scss'
import MUIBreadcrumbs from '../../breadcrumbs/Breadcrumbs';
import styles from './ParkLayout.module.scss';
import Footer from "../../footer/Footer";

const ParkLayout = ({ children, title }) => {
  return (
    <section className={styles.ParkLayout}>
      <Header title={title}/>
      <div className={styles.Container}>
        <MUIBreadcrumbs/>
        {children}
      </div>
      <Footer />
    </section>
  );
}

export default ParkLayout;