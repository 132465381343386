import useAuth from './useAuth';
import api from '../api/axios';
import { REFRESH_URL } from '../constants';
import CookieService from '../services/CookieService';
import toast from "react-hot-toast";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    let response = await api.post(`${REFRESH_URL}`, {
      accessToken: CookieService.get('access_token'),
      refreshToken: CookieService.get('refresh_token'),
    });
    if (response.data.success) {
      let dateAT = new Date();
      dateAT.setTime(dateAT.getTime() + (60 * 15 * 1000));
      const optionsAT = { path: '/', expires: dateAT }

      CookieService.set('access_token', response.data.token, optionsAT)

      setAuth(prev => {
        return {
          ...prev,
          token: response.data.token,
          firstname: response.data.firstname,
          id: response.data.id,
          role: response.data.role
        }
      });
      return { success: true, token: response.data.token };
    } else {
      toast.remove();
      toast.error('Session expirée');
      CookieService.remove('access_token');
      CookieService.remove('refresh_token');
      return { success: false, message: 'Vous n\'êtes plus connecté. Veuillez vous reconnecter.' };
    }

  }
  return refresh;
}

export default useRefreshToken;
