import FormMoveRequest from '../components/form/FormMoveRequest';
import DefaultLayout from '../components/templates/DefaultLayout/DefaultLayout';
import CartProvider from "../context/CartContext";

const MoveRequest = () => {
  return (
    <CartProvider>
      <DefaultLayout title={'Planifier une mission'}>
        <FormMoveRequest/>
      </DefaultLayout>
    </CartProvider>
  )
}

export default MoveRequest;