import {
  ACTION_URL,
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, MOVINGREQUEST_URL, TRAILER_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';
import { FcInspection } from 'react-icons/fc';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

const FullModal = ({ data, modalVisible, setModalVisible, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const updateTrailer = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Changement de statut en cours...');

    await axiosApi.post(`${MOVINGREQUEST_URL}/check`,
      {
        id: data.id
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Vous avez une action en cours pour ce conteneur. Veuillez la terminer ou la valider.' : 'Vous avez une action en cours pour cette remorque. Veuillez la terminer ou la valider.', { id: toastLoading });
          setIsLoading(false);
        } else {
          await axiosApi.post(`${ACTION_URL}/check`,
            {
              id: data.id
            })
            .then(async (result) => {
              if (result.data.success) {
                toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Vous avez une action en cours pour ce conteneur. Veuillez la terminer ou la valider.' : 'Vous avez une action en cours pour cette remorque. Veuillez la terminer ou la valider.', { id: toastLoading });
                setIsLoading(false);
              } else {
                await axiosApi.post(`${TRAILER_URL}/update/gone`,
                  {
                    id: data.id,
                    id_user: auth.id
                  })
                  .then((result) => {
                    if (result.data.success) {
                      toast.success('Sortie du parc effectuée', { id: toastLoading });
                      setIsLoading(false);
                      setRefresh(true);
                    } else {
                      toast.error('Une erreur est survenue lors du changement de statut', { id: toastLoading });
                      setIsLoading(false);
                    }
                  })
                  .catch((err) => {
                    toast.error('Une erreur est survenue', { id: toastLoading });
                    setIsLoading(false);
                  });
              }
            })
            .catch((err) => {
              toast.error('Une erreur est survenue', { id: toastLoading });
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Sortir du parc</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>

              : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            }
          </div>
          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalText}>Êtes-vous sûr de faire sortir le conteneur du parc ?</label>
              : <label className={styles.ModalText}>Êtes-vous sûr de faire sortir la remorque du parc ?</label>
            }
          </div>
          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <ModalSuccessButton
              label={'Sortir du parc'}
              onClick={() => {
                updateTrailer();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default FullModal;