import styles from './Footer.module.scss'
import useAuth from "../../hooks/useAuth";
const package_json = require('./../../../package.json');

const Footer = () => {
  const { auth } = useAuth()
  return (
    <footer className={styles.Footer}>
      <p className={styles.Left}>{auth.firstname}</p>
      <p className={styles.Center}>{'v.'+package_json.version}</p>
    </footer>
  )
}

export default Footer;
