import {
  CONTAINER_REGEX,
  ICON_SIZE16, ICON_SIZE24,
  TEMPERATURE_REGEX, TRAILER_URL
} from '../../../constants';
import styles from '../Modal.module.scss';

import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import CustomLabel from '../../label/CustomLabel';
import { useEffect, useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import CustomInput from "../../input/CustomInput";
import BasicCheckbox from "../../checkbox/BasicCheckbox";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

const EditTheorethicalTemperature = ({
                                       data,
                                       modalVisible, setModalVisible,
                                       isLoading, setIsLoading,
                                       setRefresh
                                     }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const [temperature, setTemperature] = useState(0);
  const [isDry, setIsDry] = useState(false);

  const updateTrailer = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Mise à jour en cours...');
    if (temperature === '') {
      toast.error('Veuillez sélectionner une température', { id: toastLoading });
      setIsLoading(false)
      return;
    }

    if (!TEMPERATURE_REGEX.test(temperature)) {
      toast.error('Veuillez entrer une température valide', { id: toastLoading });
      setIsLoading(false)
      return;
    }
    await axiosApi.post(`${TRAILER_URL}/update`,
      {
        id: data.id,
        id_statut: data.id_statut,
        id_tractionnaire: data.id_tractionnaire,
        temperature_theorique: temperature,
        id_type_remorque: data.id_type_remorque,
        est_sec: isDry,
        id_user: auth.id,
        est_branche: data.est_branche ? 1 : 0
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('Les données ont bien été mise à jour', { id: toastLoading });
          setIsLoading(false)
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de la mise à jour', { id: toastLoading });
          setIsLoading(false)
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false)
      });
  }

  useEffect(() => {
    if (data) {
      if (isDry) {
        setTemperature(0);
      } else {
        setTemperature(data.temperature_theorique);
      }
    }
  }, [isDry])

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label
              className={styles.HeaderText}>{CONTAINER_REGEX.test(data.num_remorque) ? 'Modifier conteneur' : 'Modifier remorque'}</label>
            <div onClick={() => {
              setModalVisible(!modalVisible);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          {!isDry &&
            <div className={styles.Wrapper50}>
              <CustomLabel
                label={'Température théorique(°C)'}
              />
              <div className={styles.TextWrapper}>
                <CustomInput
                  value={temperature}
                  setValue={setTemperature}
                  placeholder={'ex: -20'}
                  maxLength={5}
                />
              </div>
            </div>
          }
          {
            data.id_type_remorque !== 2 &&
            <div className={styles.Wrapper}>
              <BasicCheckbox
                label={'Conteneur sec'}
                value={isDry}
                setValue={setIsDry}
                disabled={false}
              />
            </div>
          }

          <BasicModalButton
            label={CONTAINER_REGEX.test(data.num_remorque) ? 'Modifier conteneur' : 'Modifier remorque'}
            onClick={() => {
              updateTrailer()
            }}
            isLoading={isLoading}
          />
        </>
      }
    </BaseModal>
  )
}

export default EditTheorethicalTemperature;