import styles from './BasicCheckbox.module.scss';

const BasicCheckbox = ({ label, value, setValue, center = false, disabled = true }) => {
  return (
    <div className={center ? styles.CenteredCheckbox : styles.BasicCheckbox}>
      <input
        type={'checkbox'}
        name={'plugged'}
        checked={value}
        onChange={() => setValue(!value)}
        disabled={disabled}
      />
      <label htmlFor='label-checkbox'>{label}</label>
    </div>
  )
}

export default BasicCheckbox;