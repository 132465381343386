import styles from './ModalButton.module.scss';
import PulseSpinner from '../../spinner/PulseSpinner';

const ModalSuccessButton = ({ label, onClick, isLoading = false }) => {
  return (
    <div className={styles.ButtonContainer}>
      <button
        className={isLoading ? styles.DisabledButton : styles.SuccessButton}
        onClick={onClick}
        disabled={isLoading}>
        {isLoading
          ? <PulseSpinner isLoading={isLoading}/>
          : label
        }
      </button>
    </div>
  )
}

export default ModalSuccessButton;