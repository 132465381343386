import {
  ACTION_URL, API_URL,
  CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24, STATUS_URL
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import ModalSuccessButton from '../../button/ModalButton/ModalSuccessButton';

import useAuth from '../../../hooks/useAuth';
import { FcInspection } from 'react-icons/fc';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import { useEffect, useState } from 'react';
import { getSelected } from '../../../utils/functions';
import TextAreaInput from '../../input/TextAreaInput';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const ActionsModal = ({ data, modalVisible, setModalVisible, isLoading, setIsLoading, setRefresh }) => {
  const axiosApi = useAxiosApi();
  const { auth } = useAuth();

  const [actions, setActions] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState('');

  const fetchData = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');

    await axiosApi.get(`${STATUS_URL}/action`)
      .then(async (result) => {
        setActions(result.data.list);
        setSelectedAction(getSelected(result.data.list, 0)[0]);
        setIsLoading(false);
        toast.remove(toastLoading);
      })
      .catch((err) => {
        toast.remove(toastLoading);
        setIsLoading(false);
      })
  }

  const createAction = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Création en cours...');
    if (!selectedAction) {
      toast.error('Veuillez sélectionner une action', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    if (selectedAction.label === 'Autre' && additionalInformation === '') {
      toast.error('Veuillez décrire l\'action', { id: toastLoading });
      setIsLoading(false);
      return;
    }

    const action = {
      id_remorque: data.id,
      id_statut: 18,
      id_statut_action: selectedAction.value,
      id_utilisateur: auth.id,
      additional_information: additionalInformation
    }

    await axiosApi.post(`${ACTION_URL}/check`,
      {
        id: data.id
      })
      .then(async (result) => {
        if (result.data.success) {
          toast.error(CONTAINER_REGEX.test(data.num_remorque) ? 'Vous avez déjà une action en cours pour ce conteneur.' : 'Vous avez déjà une action en cours pour cette remorque.', { id: toastLoading });
          setIsLoading(false);
        } else {
          await axiosApi.post(`${ACTION_URL}/create`,
            {
              action
            })
            .then((result) => {
              if (result.data.success) {
                toast.success('La demande d\'action a bien été créée', { id: toastLoading });
                setIsLoading(false);
                sendNotification()
                  .then((result) => {
                    if (result.status === 201) {
                      toast('Une notification a été envoyée aux chauffeurs de parc.',
                        {
                          icon: '📧',
                        })
                      console.log('Notification envoyé !')
                    } else if (result.status === 409) {
                      console.log('Aucun utilisateur à notifier')
                    } else {
                      console.log('Une erreur est survenue lors de l\'envoi de la notification');
                    }
                  });

                setRefresh(true);
              } else {
                toast.error('Une erreur est survenue lors de la création de l\'action', { id: toastLoading });
                setIsLoading(false);
              }
            })
            .catch((err) => {
              toast.error('Une erreur est survenue lors de la création de l\'action', { id: toastLoading });
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const sendNotification = async () => {
    return await axiosApi.get(`${API_URL}/notifications/new-mission`);
  }

  useEffect(() => {
    if (modalVisible) {
      fetchData();
    }
  }, [modalVisible]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {modalVisible !== false &&
        <>
          <div className={styles.Header}>
            <FcInspection size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Actions à faire</label>
            <div onClick={() => {
              setModalVisible(!modalVisible)
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>

          <div className={styles.Wrapper}>
            {CONTAINER_REGEX.test(data.num_remorque)
              ? <label className={styles.ModalTextBold}>Conteneur : {data.num_remorque}</label>
              : <label className={styles.ModalTextBold}>Remorque : {data.num_remorque}</label>
            }
          </div>

          <div className={styles.Wrapper}>
            <CustomLabel
              label={'Statut'}
            />
            <CustomSearchableSelect
              data={actions}
              defaultValue={selectedAction}
              placeholder={'Selectionnez une action'}
              setValue={setSelectedAction}
            />
          </div>
          {(selectedAction && selectedAction.label === 'Autre') &&
            <div className={styles.Wrapper}>
              <CustomLabel
                label={'Informations complémentaires'}
              />
              <TextAreaInput
                value={additionalInformation}
                setValue={setAdditionalInformation}
                placeholder={'Informations complémentaires'}
                maxLength={500}
              />
            </div>
          }

          <div className={styles.ButtonWrapper}>
            <ModalCancelButton
              label={'Retour'}
              onClick={() => {
                setModalVisible(false);
              }}
              isLoading={isLoading}
            />
            <ModalSuccessButton
              label={'Valider la demande'}
              onClick={() => {
                createAction();
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default ActionsModal;