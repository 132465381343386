import FormArrival from '../components/form/FormArrival';
import DefaultLayout from '../components/templates/DefaultLayout/DefaultLayout';

const Arrival = () => {
  return (
    <DefaultLayout title={'Enregistrer une arrivée'}>
      <FormArrival/>
    </DefaultLayout>
  )
}

export default Arrival;