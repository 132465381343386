import DefaultLayout from '../../components/templates/DefaultLayout/DefaultLayout';
import FormPark from '../../components/form/Park/FormPark';
import CartProvider from "../../context/CartContext";

const Park = () => {
  return (
    <CartProvider>
      <DefaultLayout title={'Suivi global'}>
        <FormPark/>
      </DefaultLayout>
    </CartProvider>
  )
}

export default Park;