import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Login from './views/Login';
import Home from './views/Home';
import RequireAuth from './components/RequireAuth';
import Arrival from './views/Arrival';
import styles from './styles/global.module.scss'
import MoveRequest from './views/MoveRequest';
import Mission from './views/Mission/Mission';
import Missing from './views/Missing';
import TrailerSearch from './views/TrailerSearch';
import PersistLogin from './components/PersistLogin';
import ParkHome from './views/Park/ParkHome';
import Park from './views/Park/Park';
import ParkTractionnist from './views/Park/ParkTractionnist';
import Temperature from "./views/Temperature/Temperature";
import TemperatureHome from "./views/Temperature/TemperatureHome";
import ParkBuilding from "./views/Park/ParkBuilding";
import TemperatureOnCall from "./views/Temperature/TemperatureOnCall";
import { Toaster } from 'react-hot-toast';
import Export from "./views/Export";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from "react";


const App = () => {
  useEffect(()=> {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceWorker.js').then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);

          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data === 'updateAvailable') {
              toast.info('Une nouvelle version est diponible. Appuyez-ici pour mettre à jour.', {
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                onClick: () => {
                  window.location.reload();
                }
              });
            }
          });

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  console.log('New content is available; please refresh.');
                } else {
                  console.log('Content is cached for offline use.');
                }
              }
            };
          };
        }).catch((error) => {
          console.error('Error during service worker registration:', error);
        });
      });
    }

    if (!('PushManager' in window)) {
      console.log(('push isnt supported'))
    }
  },[])


  return (
    <main className={styles.App}>
      <Toaster />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route element={<PersistLogin/>}>
            <Route element={<RequireAuth/>}>
              <Route path='/' element={<Home/>}/>
              <Route path='/arrival' element={<Arrival/>}/>
              <Route path='/moverequest' element={<MoveRequest/>}/>
              <Route path='/search' element={<TrailerSearch/>}/>
              <Route path='/mission' element={<Mission/>}/>
              <Route path='/park' element={<ParkHome/>}/>
              <Route path='/park/global' element={<Park/>}/>
              <Route path='/park/tractionnist' element={<ParkTractionnist/>}/>
              <Route path='/park/building' element={<ParkBuilding/>}/>
              <Route path='/temperature' element={<TemperatureHome/>}/>
              <Route path='/temperature/global' element={<Temperature/>}/>
              <Route path='/temperature/oncall' element={<TemperatureOnCall/>}/>
              <Route path='/export' element={<Export/>}/>
            </Route>
          </Route>
          <Route path='login' element={<Login/>}/>
          <Route path='*' element={<Missing/>}/>
        </Route>
      </Routes>
    </main>
  )
    ;
}

export default App;
