import styles from './BasicButton.module.scss';
import PulseSpinner from '../../spinner/PulseSpinner';

const BasicButton = ({ label, onClick, isLoading = false }) => {
  return (
    <div className={styles.BasicButtonContainer}>
      <button
        type={'submit'}
        className={isLoading ? styles.DisabledButton : styles.BasicButton}
        onClick={onClick}
        disabled={isLoading}>
        {isLoading
          ? <PulseSpinner isLoading={isLoading}/>
          : label
        }
      </button>
    </div>
  )
}

export default BasicButton;