import { CONTAINER_REGEX, ICON_SIZE16, ICON_SIZE24, MOVINGREQUEST_URL } from '../../../constants';
import styles from '../Modal.module.scss';
import { ImCross } from 'react-icons/im';
import TextAreaInput from '../../input/TextAreaInput';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import { useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import useAuth from "../../../hooks/useAuth";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";
import { render } from "@react-email/render";
import moment from "moment-timezone";
import DeleteMissionBuildingEmail from "../../mail/DeleteMissionBuildingEmail";

const CancelModal = ({
                       data,
                       mainModal,
                       setMainModal,
                       modalVisible,
                       setModalVisible,
                       isLoading,
                       setIsLoading,
                       setRefresh
                     }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [cancelReason, setCancelReason] = useState('');

  const cancelMoveRequest = async (id) => {
    setIsLoading(true);
    const toastLoading = toast.loading('Annulation en cours...');
    let emailHtml = '';
    emailHtml = render(<DeleteMissionBuildingEmail container={data.num_remorque}
                                                   code_batiment={data.code_batiment_demande}
                                                   emplacement={data.emplacement_demande}
                                                   information_complementaire={data.information_complementaire}
                                                   demandeur={auth.firstname}
                                                   date={moment(data.date_demande.substring(0, 16)).format('DD/MM/YYYY HH:mm')}
                                                   motif={cancelReason}/>);
    await axiosApi.post(`${MOVINGREQUEST_URL}/update`,
      {
        id,
        id_statut: 21,
        motif_annulation: cancelReason,
        id_utilisateur: auth.id,
        html: emailHtml,
        code_batiment: data.code_batiment_demande,
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('Annulation prise en compte.', { id: toastLoading });
          setIsLoading(false);
          setRefresh(true);
        } else {
          toast.error('Une erreur est survenue lors de l\'annulation', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue lors de l\'annulation', { id: toastLoading });
        setIsLoading(false);
      });
  }

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
    >
      {data !== null &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Annulation mission</label>
            <div onClick={() => {
              setModalVisible(!modalVisible)
              setMainModal(!mainModal);
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.Wrapper}>
            <label className={styles.ModalTextBold}> {CONTAINER_REGEX.test(data.num_remorque) ? `Conteneur : ${data.num_remorque}` : `Remorque : ${data.num_remorque}`}</label>
          </div>
          <div className={styles.WrapperColumn}>
            <label className={styles.ModalText}>Motif annulation :</label>
            <TextAreaInput
              value={cancelReason}
              setValue={setCancelReason}
              placeholder={'Motif d\'annulation'}
              maxLength={500}
            />
          </div>
          <div className={styles.Wrapper}>
            <BasicModalButton
              label={'Valider l\'annulation'}
              onClick={() => {
                cancelMoveRequest(data.id)
              }}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    </BaseModal>
  )
}

export default CancelModal;