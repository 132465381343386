import styles from '../Forms.module.scss';
import CustomLabel from '../../label/CustomLabel';
import CustomSearchableSelect from '../../select/CustomSearchableSelect/CustomSearchableSelect';
import { useEffect, useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import { BUILDING_URL, TRAILER_URL } from '../../../constants';
import CustomCenteredLabel from '../../label/CustomCenteredLabel';
import CustomTemperatureText from "../../text/CustomTemperatureText";
import toast from "react-hot-toast";

const FormTemperatureOnCall = () => {
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [buildings, setBuildings] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [baseTrailers, setBaseTrailers] = useState(null);
  const [trailers, setTrailers] = useState([]);
  const [selectedTrailer, setSelectedTrailer] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');

    await axiosApi.get(`${BUILDING_URL}/all`)
      .then((result) => {
        if (result) {
          setBuildings(result.data.list);
          setIsLoading(false);
          toast.remove(toastLoading);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });

    getTrailers()
      .then(() => {
        filterTrailers();
      })

  }

  const getTrailers = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Chargement en cours...');
    setTrailers([]);
    await axiosApi.get(`${TRAILER_URL}/temperature`)
      .then((result) => {
        if (result) {
          setBaseTrailers(result.data.list);
          setIsLoading(false);
          toast.remove(toastLoading);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });
  }

  const filterTrailers = () => {
    if(baseTrailers){
      let filteredArray = baseTrailers;
      filteredArray = filteredArray.filter(temp => temp.plus_de_6h === 1);

      if(selectedTrailer){
        filteredArray = filteredArray.filter(temp => temp.label === selectedTrailer.label);
      }
      if (selectedBuilding) {
        filteredArray = filteredArray.filter(temp => temp.code_batiment === selectedBuilding.value);
      }
      setTrailers(filteredArray);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    filterTrailers();
  }, [selectedBuilding, refresh]);

  useEffect(() => {
    if (refresh) {
      fetchData()
        .then(() => {
          setRefresh(false);
        });
    }
  }, [refresh]);

  useEffect(() => {
    if (baseTrailers) {
      filterTrailers();
    }
  }, [baseTrailers]);

  useEffect(() => {
    filterTrailers();
  }, [selectedTrailer]);


  return (
    <div className={styles.Form}>
      <div className={styles.Wrapper}>
        <div className={styles.SplitSelect}>
          <div className={styles.position}>
            <CustomLabel
              label={'Bâtiment'}
            />
            <CustomSearchableSelect
              data={buildings}
              defaultValue={selectedBuilding}
              placeholder={'Sélectionner un bâtiment'}
              setValue={setSelectedBuilding}
              isClearable
            />
          </div>
        </div>
      </div>

      {trailers !== null
        &&
        <>
          <CustomLabel
            label={'Rechercher N°Conteneur / Remorque'}
          />
          <div className={styles.Wrapper}>
            <CustomSearchableSelect
              data={trailers}
              defaultValue={selectedTrailer}
              placeholder={'ex: CAXU123456/7 ou AA-001-AA'}
              setValue={setSelectedTrailer}
              searchable
              isClearable
            />
          </div>
          <div className={styles.Wrapper}>
            {
              trailers.length > 0 ?
                trailers.map((elem) => {
                  return (
                    <CustomTemperatureText
                      data={elem}
                      key={elem.id}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      setRefresh={setRefresh}
                    />
                  )
                })
                :
                <CustomCenteredLabel label={'Aucun conteneur/remorque sur le bâtiment'}/>
            }
          </div>
        </>
      }
    </div>
  )
}

export default FormTemperatureOnCall;