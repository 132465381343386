import { API_URL, ICON_SIZE40 } from '../constants';
import Widget from '../components/widget/Widget';
import { RiArrowLeftRightFill, RiTruckLine } from 'react-icons/ri';
import { BiSearch } from 'react-icons/bi';
import { FaParking, FaTasks, FaTemperatureLow, FaFileCsv } from 'react-icons/fa';
import DefaultLayout from '../components/templates/DefaultLayout/DefaultLayout';
import styles from '../styles/global.module.scss'
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosApi from "../hooks/useAxiosApi";
import { urlB64ToUint8Array } from "../utils/functions";

const Home = () => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();
  const initNotification = async () => {
    if (auth.role === 1 || auth.role === 3) {
      const result = await Notification.requestPermission();
      if (result === 'denied') {
        console.error('The user explicitly denied the permission request.');
        return;
      }
      if (result === 'granted') {
        console.info('The user accepted the permission request.');
        const registration = await navigator.serviceWorker.getRegistration();
        const subscribed = await registration.pushManager.getSubscription();
        if (subscribed) {
          console.info('User is already subscribed.');
          return;
        }
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
        });
        await axiosApi.post(`${API_URL}/notifications/subscribe`,
          {
            body: subscription
          })
          .then((result) => {
            // setIsReqLoading(false);
            if (result.data.success) {
              console.log('Subscription successfull')
            } else {
              console.log('Subscription error')
            }
          })
          .catch((err) => {
            console.log('ERROR')
          })
      }
    }
  }

  useEffect(() => {
    initNotification();
  }, []);

  return (
    <DefaultLayout title={'Accueil'}>
      <div className={styles.WidgetWrapper}>
        <Widget
          path={'arrival'}
          icon={<RiTruckLine size={ICON_SIZE40}/>}
          title_1={'Enregistrer'}
          title_2={'une arrivée'}
        />
        <Widget
          path={'search'}
          icon={<BiSearch size={ICON_SIZE40}/>}
          title_1={'Recherche'}
          title_2={'conteneur'}
        />
        {auth.role !== 1 &&
          <Widget
            path={'moverequest'}
            icon={<RiArrowLeftRightFill size={ICON_SIZE40}/>}
            title_1={'Planifier une'}
            title_2={'mission'}
          />
        }
        <Widget
          path={'mission'}
          icon={<FaTasks size={ICON_SIZE40}/>}
          title_1={'Missions'}
        />
        <Widget
          path={'park'}
          icon={<FaParking size={ICON_SIZE40}/>}
          title_1={'État du parc'}
        />
        <Widget
          path={'temperature'}
          icon={<FaTemperatureLow size={ICON_SIZE40}/>}
          title_1={'Gestion de'}
          title_2={'température'}
        />
        {(auth.role === 3 || auth.role === 4) &&
          <Widget
            path={'export'}
            icon={<FaFileCsv size={ICON_SIZE40}/>}
            title_1={'Export CSV'}
            title_2={''}
          />
        }
      </div>
    </DefaultLayout>
  );
};


export default Home;