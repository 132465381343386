import {
  customStylesDatatable,
  ICON_SIZE16,
  ICON_SIZE24,
  customStylesCart, MOVINGREQUEST_URL, API_URL,
} from '../../../constants';
import styles from '../Modal.module.scss';
import { ImBin, ImCross } from 'react-icons/im';
import useAxiosApi from '../../../hooks/useAxiosApi';
import BasicModalButton from '../../button/ModalButton/BasicModalButton';
import { useState } from 'react';
import { FcViewDetails } from 'react-icons/fc';
import { CartContext } from "../../../context/CartContext";
import { useContext, useEffect } from 'react';
import DataTable from "react-data-table-component";
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const CartModal = ({
                     modalVisible,
                     setModalVisible,
                     isLoading,
                     setIsLoading,
                   }) => {
  const axiosApi = useAxiosApi();
  const cart = useContext(CartContext);

  const [cartMission, setCartMission] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      name: 'Numéro',
      selector: row => row.num_remorque,
      sortable: true,
      center: true
    },
    {
      name: 'Bâtiment',
      selector: row => row.code_batiment,
      center: true
    },
    {
      name: 'Emplacement',
      selector: row => row.emplacement,
      center: true
    },
    {
      name: 'Statut',
      selector: row => row.status_label,
      center: true
    },
    {
      name: 'Date demande',
      selector: row => row.date_demande,
      center: true,
    },
    {
      name: 'Information complémentaire',
      selector: row => row.information_complementaire,
      center: true
    },
    {
      name: 'Supprimer',
      cell: (row) => <ImBin className={styles.CrossLogo} color={'red'} onClick={() => {
        cart.deleteFromCart(row.id_remorque);
        setRefresh(true);
      }} size={ICON_SIZE16}/>,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true
    },
  ];

  const sendNotification = async () => {
    return await axiosApi.get(`${API_URL}/notifications/distribution`);
  }

  const sendMissions = async () => {
    setIsLoading(true);
    toast.loading('Création des missions en cours...', { id: 'loading', });
    const promises = [];
    let error = false;
    for (const mission of cartMission) {
      const result = axiosApi.post(`${MOVINGREQUEST_URL}/create`,
        {
          newMoveRequest: mission
        })
      promises.push(result);
    }

    const results = await Promise.all(promises);

    results.map((result) => {
      if (result.data.success) {
        cart.deleteFromCart(result.data.id)
        return true;
      } else {
        error = true;
        toast.error(`Une erreur est survenue lors de la création de la mission : ${result.data.num_remorque}`, { id: 'loading' });
        return false;
      }
    })

    if (!error) {
      sendNotification()
        .then((result) => {
          if (result.status === 201) {
            console.log('Notification envoyé !');
            toast.success('La répartition a été envoyée !', { id: 'loading' });
            toast('Notification envoyé', {
              icon: '📬',
            });
            fetchCart();
          } else if (result.status === 409) {
            console.log('Aucun utilisateur à notifier');
            toast.success('La répartition a été envoyée !', { id: 'loading' });
            fetchCart();
          } else {
            toast.error('Erreur lors de l\'envoi de la répartition.', { id: 'loading' });
            console.log('Une erreur est survenue lors de l\'envoi de la notification');
            fetchCart();
          }
        });
      setIsLoading(false);
    } else {
      toast.error('La notification de répartition n\'a pas été envoyé car une des missions a rencontré une erreur lors de sa création.', { id: 'loading' });
      setIsLoading(false);
      setRefresh(true);
    }
  }

  const fetchCart = () => {
    let array = []
    Object.keys(sessionStorage).map((mission) => {
      let item = JSON.parse(sessionStorage.getItem(mission));
      return array.push(item);
    });

    array.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    setCartMission(array);
  }

  useEffect(() => {
    if (modalVisible) {
      fetchCart();
    }
  }, [modalVisible])

  useEffect(() => {
    if (refresh) {
      fetchCart();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <BaseModal
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      style={customStylesCart}
    >
      {modalVisible &&
        <>
          <div className={styles.Header}>
            <FcViewDetails size={ICON_SIZE24}/>
            <label className={styles.HeaderText}>Panier des missions</label>
            <div onClick={() => {
              setModalVisible(!modalVisible)
            }}>
              <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
            </div>
          </div>
          <div className={styles.WrapperCart}>
            {!isLoading &&
            cartMission.length !== 0 ?
              <DataTable
                title={'Détails missions'}
                columns={columns}
                data={cartMission}
                customStyles={customStylesDatatable}
                dense
                highlightOnHover
                responsive
              />
              : 'Panier vide'
            }
          </div>
          {cartMission.length !== 0 &&
            <>
              <div className={styles.Wrapper}>
                <BasicModalButton
                  label={'Valider la répartition'}
                  onClick={() => {
                    sendMissions();
                  }}
                  isLoading={isLoading}
                />
              </div>
            </>
          }
        </>
      }
    </BaseModal>
  )
}

export default CartModal;