import Header from '../../header/Header';
import Container from '../../container/Container';
import styles from '../../../styles/global.module.scss';
import MUIBreadcrumbs from '../../breadcrumbs/Breadcrumbs';
import Footer from "../../footer/Footer";

const DefaultLayout = ({ children, title }) => {
  return (
    <section className={styles.DefaultLayout}>
      <Header title={title}/>
      <Container>
        <MUIBreadcrumbs/>
        {children}
      </Container>
      <Footer />
    </section>
  );
}

export default DefaultLayout;