import styles from './Label.module.scss';

const CustomLabel = ({ label }) => {
  return (
    <div className={styles.CustomLabel}>
      <label className={styles.Label}>{label}</label>
    </div>
  )
}

export default CustomLabel;