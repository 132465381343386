import styles from '../Modal.module.scss';
import {
  ACTION_URL, CONTAINER_REGEX,
  ICON_SIZE16,
  ICON_SIZE24,
} from '../../../constants';

import { ImCross } from 'react-icons/im';
import { useState } from 'react';
import useAxiosApi from '../../../hooks/useAxiosApi';
import useAuth from '../../../hooks/useAuth';
import { FcViewDetails } from 'react-icons/fc';
import ModalCancelButton from '../../button/ModalButton/ModalCancelButton';
import Button60 from '../../button/Button60/Button60';
import CancelWaitingActionModal from './CancelWaitingActionModal';
import BaseModal from "../BaseModal";
import toast from "react-hot-toast";

const ActionDetailModal = ({ data, modalVisible, setModalVisible, setRefresh }) => {
  const { auth } = useAuth()
  const axiosApi = useAxiosApi();

  const [isLoading, setIsLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const updatingAction = async () => {
    setIsLoading(true);
    const toastLoading = toast.loading('Mise à jour en cours...');
    await axiosApi.post(`${ACTION_URL}/update`,
      {
        id: data.id,
        id_statut: 19,
        id_utilisateur: auth.id
      })
      .then((result) => {
        if (result.data.success) {
          toast.success('L\'action à été pris en compte avec succès !', { id: toastLoading });
          setRefresh(true);
        } else {
          toast.error('Erreur', { id: toastLoading });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error('Une erreur est survenue', { id: toastLoading });
        setIsLoading(false);
      });

  }

  return (
    <>
      <BaseModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      >
        {data !== null &&
          <>
            <div className={styles.Header}>
              <FcViewDetails size={ICON_SIZE24}/>
              <label className={styles.HeaderText}>Détail de l'action</label>
              <div onClick={() => {
                setIsLoading(false);
                setModalVisible(!modalVisible);
              }}>
                <ImCross size={ICON_SIZE16} className={styles.CrossLogo}/>
              </div>
            </div>
            <div className={styles.WrapperRow}>
              {CONTAINER_REGEX.test(data.num_remorque)
                ? <label className={styles.ModalText}>Conteneur :</label>

                : <label className={styles.ModalText}>Remorque :</label>
              }
              <label className={styles.ModalText}>
                <label className={styles.ModalTextBold}>{data.num_remorque}</label>
              </label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Position actuelle :</label>
              <label className={styles.ModalText}>
                <label className={styles.ModalTextBold}>{data.batiment}-{data.emplacement}</label>
              </label>
            </div>
            <div className={styles.WrapperRow}>
              <label className={styles.ModalText}>Intitulé action :</label>
              <label
                className={styles.ModalText}>{data.libelle}</label>
            </div>

            {data.information_complementaire !== '' &&
              <div className={styles.WrapperRow}>
                <label className={styles.ModalText}>Informations complémentaires :</label>
                <label
                  className={styles.ModalText}>{data.information_complementaire !== null ? data.information_complementaire : 'Aucunes informations'}</label>
              </div>
            }

            <div className={styles.ButtonWrapper}>
              {(auth.role === 2 || auth.role === 3 || auth.role === 4) &&
                <ModalCancelButton
                  label={'Annuler'}
                  onClick={() => {
                    setModalVisible(false);
                    setCancelModalVisible(!cancelModalVisible);
                  }}
                  isLoading={isLoading}
                />
              }
              {(auth.role === 1 || auth.role === 3 || auth.role === 4) &&
                <Button60
                  label={'Confirmer la prise en compte'}
                  onClick={() => {
                    updatingAction();
                  }}
                  isLoading={isLoading}
                />
              }
            </div>
          </>
        }
      </BaseModal>
      <CancelWaitingActionModal
        data={data}
        mainModal={modalVisible}
        setMainModal={setModalVisible}
        modalVisible={cancelModalVisible}
        setModalVisible={setCancelModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </>

  )
}

export default ActionDetailModal;