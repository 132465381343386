import styles from './Button60.module.scss';
import PulseSpinner from '../../spinner/PulseSpinner';

const Button60 = ({ label, onClick, isLoading = false }) => {
  return (
    <div className={styles.BasicButtonContainer}>
      <button
        className={isLoading ? styles.DisabledButton : styles.BasicButton}
        onClick={onClick}
        disabled={isLoading}>
        {isLoading
          ? <PulseSpinner isLoading={isLoading}/>
          : label
        }
      </button>
    </div>
  )
}

export default Button60;