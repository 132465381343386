import ClipLoader from 'react-spinners/ClipLoader'

const Spinner = ({ size = 30, isLoading = false }) => {
  return (
    <ClipLoader
      size={size}
      color={'#28A745'}
      loading={isLoading}
    />
  )
}

export default Spinner;