import { useState } from 'react';
import styles from './CustomText.module.scss';
import { CONTAINER_REGEX, ICON_SIZE24, MOVINGREQUEST_URL, TRAILER_REGEX, TRAILER_URL } from '../../constants';
import { FcCancel, FcCheckmark, FcClapperboard, FcMediumPriority } from 'react-icons/fc';
import CustomIconLabel from '../label/CustomIconLabel';
import FullContainerModal from '../modal/ParkState/FullContainerModal';
import EmptyContainerModal from '../modal/ParkState/EmptyContainerModal';
import GoneModal from '../modal/ParkState/GoneModal';
import EmptyTrailerModal from '../modal/ParkState/EmptyTrailerModal';
import FullTrailerModal from '../modal/ParkState/FullTrailerModal';
import ActionsModal from '../modal/ParkState/ActionsModal';
import { RiArrowLeftRightFill } from "react-icons/ri";
import MissionRequestModal from "../modal/ParkState/MissionRequestModal";
import moment from "moment-timezone";
import useAuth from "../../hooks/useAuth";
import FullTrailerTransfertModal from "../modal/ParkState/FullTrailerTransfertModal";
import EmptyTrailerTransfertModal from "../modal/ParkState/EmptyTrailerTransfertModal";
import { GrInProgress } from "react-icons/gr";
import toast from "react-hot-toast";
import useAxiosApi from "../../hooks/useAxiosApi";

const CustomParkText = ({ data, isLoading, setIsLoading, setRefresh }) => {
  const { auth } = useAuth();
  const axiosApi = useAxiosApi();

  const [clicked, setClicked] = useState('false');
  const [actionsModal, setActionsModal] = useState(false);
  const [fullContainerModalVisible, setFullContainerModalVisible] = useState(false);
  const [fullTrailerModalVisible, setFullTrailerModalVisible] = useState(false);
  const [emptyContainerModalVisible, setEmptyContainerModalVisible] = useState(false);
  const [emptyTrailerModalVisible, setEmptyTrailerModalVisible] = useState(false);
  const [goneModalVisible, setGoneModalVisible] = useState(false);
  const [missionModal, setMissionModal] = useState(false);
  const [fullTrailerTransfertModalVisible, setFullTrailerTransfertModalVisible] = useState(false);
  const [emptyTrailerTransfertModalVisible, setEmptyTrailerTransfertModalVisible] = useState(false);

  const id = data.id;

  const toggle = id => {
    if (clicked === id) {
      return setClicked(null);
    }

    setClicked(id);
  }
  return (
    <div className={styles.WrapperColumn}>
      <div className={styles.TextWrapperColumn}>
        <div className={styles.Wrapper} onClick={async () => {
          toggle(id);
        }}>
          <div className={styles.WrapperRow}>
            <label className={styles.TextBold}>{data.code_batiment}-{data.emplacement} : {data.num_remorque}</label>
            <label className={styles.ParkTextRight}>Statut : {data.libelle_statut}</label>
          </div>

          {clicked === id ?
            <>
              <div className={styles.WrapperRowParkExtend}>
                {
                  data.date_arrivee &&
                  <label className={styles.ParkTextLeft}>
                    {'Date d\'arrivée : ' + moment(data.date_arrivee.substring(0, 16)).format('DD/MM/YYYY HH:mm')}
                  </label>
                }
                <label
                  className={styles.ParkTextRight}>{'Température : ' + data.temperature_theorique + '°C'}</label>
              </div>
              <div className={styles.Actions}>
                {((data.libelle_statut === 'Vide en attente reprise'
                      || (data.libelle_statut === 'Plein en attente reprise')
                      || (data.libelle_statut === 'Vide à retirer')
                      || (data.libelle_statut === 'Plein à surveiller'))
                    || (auth.role === 3 || auth.role === 4)
                  ) &&

                  <div className={styles.WrapperIcon} onClick={() => {
                    setGoneModalVisible(true);
                  }}>
                    <FcMediumPriority size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Parti'}/>
                  </div>
                }

                {(data.libelle_statut === 'Plein à dépoter') &&
                  <div className={styles.WrapperIcon} onClick={async () => {
                    const toastLoading = toast.loading('Changement de statut en cours...');

                    await axiosApi.post(`${TRAILER_URL}/update/status`,
                      {
                        id: data.id,
                        id_status: 29,
                        isPlugged: 0,
                        id_user: auth.id
                      })
                      .then(async (result) => {
                        toast.success('Changement de statut effectuée.', { id: toastLoading });
                        setRefresh(true);
                      })
                      .catch((err) => {
                        toast.error('Une erreur est survenue', { id: toastLoading });
                        setIsLoading(false);
                      });
                  }}>
                    <GrInProgress size={ICON_SIZE24}/>
                    <CustomIconLabel label={'En cours de dépotage'}/>
                  </div>
                }

                {(data.libelle_statut === 'En cours de dépotage' && TRAILER_REGEX.test(data.num_remorque) && data.libelle !== 'EFBS') &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setEmptyTrailerModalVisible(true);
                  }}>
                    <FcCancel size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Vide à retirer'}/>
                  </div>
                }

                {(data.libelle_statut === 'En cours de dépotage' && CONTAINER_REGEX.test(data.num_remorque)) &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setEmptyContainerModalVisible(true);
                  }}>
                    <FcCancel size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Vide attente reprise'}/>
                  </div>
                }

                {(data.libelle_statut === 'Vide à empoter' && !TRAILER_REGEX.test(data.num_remorque)) &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setFullContainerModalVisible(true);
                  }}>
                    <FcCheckmark size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Plein attente reprise'}/>
                  </div>
                }

                {(data.libelle_statut === 'Vide à empoter' && TRAILER_REGEX.test(data.num_remorque)) &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setFullTrailerModalVisible(true);
                  }}>
                    <FcCheckmark size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Plein à surveiller'}/>
                  </div>
                }
                {(data.libelle_statut === 'Vide à empoter' && TRAILER_REGEX.test(data.num_remorque) && data.libelle === "EFBS") &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setFullTrailerTransfertModalVisible(true);
                  }}>
                    <FcCheckmark size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Plein en attente reprise'}/>
                  </div>
                }

                {(data.libelle_statut === 'Plein en attente de consigne' && CONTAINER_REGEX.test(data.num_remorque)) &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setMissionModal(true);
                  }}>
                    <RiArrowLeftRightFill color={'#28A745'} size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Planifier mission'}/>
                  </div>
                }
                {(data.libelle_statut === 'Plein à dépoter' && TRAILER_REGEX.test(data.num_remorque) && data.libelle === "EFBS") &&
                  <div className={styles.WrapperIcon} onClick={() => {
                    setEmptyTrailerTransfertModalVisible(true);
                  }}>
                    <FcCancel size={ICON_SIZE24}/>
                    <CustomIconLabel label={'Vide en attente reprise'}/>
                  </div>
                }

                <div className={styles.WrapperIcon} onClick={() => {
                  setActionsModal(true);
                }}>
                  <FcClapperboard size={ICON_SIZE24}/>
                  <CustomIconLabel label={'Actions'}/>
                </div>
              </div>
            </>

            : null
          }
        </div>
      </div>
      <ActionsModal
        data={data}
        modalVisible={actionsModal}
        setModalVisible={setActionsModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <FullContainerModal
        data={data}
        modalVisible={fullContainerModalVisible}
        setModalVisible={setFullContainerModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <FullTrailerModal
        data={data}
        modalVisible={fullTrailerModalVisible}
        setModalVisible={setFullTrailerModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <EmptyContainerModal
        data={data}
        modalVisible={emptyContainerModalVisible}
        setModalVisible={setEmptyContainerModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <EmptyTrailerModal
        data={data}
        modalVisible={emptyTrailerModalVisible}
        setModalVisible={setEmptyTrailerModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <GoneModal
        data={data}
        modalVisible={goneModalVisible}
        setModalVisible={setGoneModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <MissionRequestModal
        data={data}
        modalVisible={missionModal}
        setModalVisible={setMissionModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <FullTrailerTransfertModal
        data={data}
        modalVisible={fullTrailerTransfertModalVisible}
        setModalVisible={setFullTrailerTransfertModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
      <EmptyTrailerTransfertModal
        data={data}
        modalVisible={emptyTrailerTransfertModalVisible}
        setModalVisible={setEmptyTrailerTransfertModalVisible}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefresh={setRefresh}
      />
    </div>
  )
}

export default CustomParkText;